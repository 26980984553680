import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../../hooks/useStore';
import { appealAccountClosure } from '../../../../microservices/users';
import { translate } from '../../../../services/translate';
import { stores } from '../../../../stores';
import Ui2FormTextArea from '../../../ui-2/form/text-area/Ui2FormTextArea';
import Ui2Form from '../../../ui-2/form/Ui2Form';
import UiAlert from '../../../ui/alert/UiAlert';
import UiButton from '../../../ui/button/UiButton';
import UiGroup from '../../../ui/group/UiGroup';

interface Props {
    close: () => void;
    questions: string[];
    setError: (error: string) => void;
}

export default function AuthAppealForm({ close, questions, setError }: Props) {
    const [appealId] = useStore(stores.appeal.id);
    const [isAppealSent, setIsAppealSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const useFormMethods = useForm();

    async function appeal() {
        setIsLoading(true);
        try {
            const answers = useFormMethods.getValues();
            await appealAccountClosure(
                appealId,
                questions.map((question, index) => ({ question, answer: answers[`question-${index}`] })),
            );
            setIsAppealSent(true);
        } catch (error) {
            setError(translate('Something went wrong', 'ui.common'));
            setIsLoading(false);
        }
    }

    return (
        <Ui2Form useFormMethods={useFormMethods} onSubmit={appeal}>
            {questions.map((question, index) => (
                <Ui2FormTextArea
                    disabled={isAppealSent}
                    key={`question-${index}`}
                    name={`question-${index}`}
                    label={`${index + 1}. ${question}`}
                    maxLength={200}
                    rows={2}
                />
            ))}
            {isAppealSent && (
                <UiAlert success>
                    {translate(
                        'Thank you for sending your appeal. Our support will contact you via email soon',
                        'ui.account',
                    )}
                    .
                </UiAlert>
            )}
            <UiGroup>
                <UiButton block type="button" onClick={close}>
                    {translate('Back', 'ui.common')}
                </UiButton>
                {!isAppealSent && (
                    <UiButton block type="button" color="primary" isLoading={isLoading} onClick={appeal}>
                        {translate('Send', 'ui.common')}
                    </UiButton>
                )}
            </UiGroup>
        </Ui2Form>
    );
}
