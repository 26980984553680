import React from 'react';
import Wrapper from './styles';
import { useLoyalty } from '../../hooks/useLoyalty';
import { stores } from '../../stores';
import { useStore } from '../../hooks/useStore';
import ReactTooltip from 'react-tooltip';
import { translate } from '../../services/translate';

export default function LoyaltyMeter() {
    const TOOLTIP_NAME = 'loyalty-meter';

    const [user] = useStore(stores.user);
    const {
        level: { level, percent, currentLevelXp, xpToNextLevel },
    } = useLoyalty(user?.id);

    if (!level) {
        return null;
    }

    return (
        <Wrapper data-for={TOOLTIP_NAME} data-tip data-event="click">
            <span className="progress-level">{level}</span>
            <svg
                className="progress-bar"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                style={{ transform: 'rotate(-90deg)', ['--progress' as string]: percent }}
            >
                <circle className="background" cx="19" cy="19" r="16" fill="none" strokeWidth="6" />
                <circle
                    className="foreground"
                    cx="19"
                    cy="19"
                    r="16"
                    fill="none"
                    strokeWidth="6"
                    pathLength="100"
                    strokeDasharray="100"
                />
            </svg>
            <ReactTooltip
                id={TOOLTIP_NAME}
                place="bottom"
                effect="solid"
                globalEventOff="click"
                className="loyalty-tooltip"
            >
                <div>
                    <div className="loyalty-label">{translate('current-xp-points', 'ui.sim')}</div>
                    <div className="loyalty-points">{currentLevelXp}</div>
                </div>
                <div>
                    <div className="loyalty-label">{translate('xp-to-next-level', 'ui.sim')}</div>
                    <div className="loyalty-points">{xpToNextLevel}</div>
                </div>
            </ReactTooltip>
        </Wrapper>
    );
}
