import React from 'react';
import { translate } from '../../../../services/translate';
import { shareBetslip } from '../../../../microservices/bets';
import { getStoreValue } from '../../../../stores/store/utils';
import { stores } from '../../../../stores';
import { useStore } from '../../../../hooks/useStore';
import { COMBO_MARKET_ID } from '../../../../services/sports/constants';
import { TicketType } from '@staycool/bets-types';
import { TAG_MANAGER_EVENT } from '../../../../services/analytics';
import UiShareButton from '../../../ui/share-button/UiShareButton';

interface Props {
    outcomeIds: number[];
    betType: TicketType;
    comboCardId?: number;
}

export default function SportBetslipShareButton({ outcomeIds, betType, comboCardId }: Props) {
    const outcomeIdByMarketId = getStoreValue(stores.sports.betSlipMarketIdToOutcomeId);

    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const genericErrors = betSlipErrorByMarketId[String(COMBO_MARKET_ID)] || [];
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const marketIds = Object.keys(outcomeIdByMarketId);

    const shouldDisplayShareButton =
        !['betbuilder', 'teaser'].includes(betType) &&
        marketIds.every((marketId) => marketInfoById[Number(marketId)]?.match_status === 'OPEN');

    const contentMessage = translate(`Feeling confident about my bet! Stay cool and good luck!`, 'ui.betslip');

    async function handleShareBetslipButtonClick() {
        const { code } = await shareBetslip(outcomeIds, betType, comboCardId);
        const postfix = `/shared-betslip?betslipCode=${code}`;

        return postfix;
    }

    if (!shouldDisplayShareButton) {
        return null;
    }

    return (
        <UiShareButton
            buttonDescription={translate('Share betslip', 'ui.betslip')}
            modalTitle={translate('Share Your Betslip', 'ui.betslip')}
            contentMessage={contentMessage}
            analytic={TAG_MANAGER_EVENT.BETSLIP}
            disabled={Boolean(genericErrors.length)}
            onShareButtonClick={handleShareBetslipButtonClick}
        />
    );
}
