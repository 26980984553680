import { Currency } from '@staycool/currency-helper';
import { Country } from '@staycool/location';
import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { logger } from '../services/logger';
import { Jackpot } from '../types/casino-thrilltech-jackpot/types';

const getUrl = (url) => getServiceUrl('casino-thrilltech-jackpots', url);

export async function getAvailableJackpots(payload: { country: Country; currency: Currency }) {
    try {
        const url = getUrl('fo/jackpots');
        return await httpGet<Jackpot[]>(url, payload);
    } catch (error) {
        logger.error('CasinoThrilltechJackpotsMicroservice', 'getAvailableJackpots', error);
        return [];
    }
}

export async function getUserJackpots(payload: { country: Country; currency: Currency }) {
    try {
        const url = getUrl('fo/jackpots/user');
        return await httpGet<Jackpot[]>(url, payload);
    } catch (error) {
        logger.error('CasinoThrilltechJackpotsMicroservice', 'getUserJackpots', error);
        return [];
    }
}

export async function optIn(payload: { jackpotId: number; contributionValue?: number; userCurrency: Currency }) {
    try {
        const url = getUrl('fo/jackpot/opt-in');
        return await httpPost(url, payload);
    } catch (error) {
        logger.error('CasinoThrilltechJackpotsMicroservice', 'optIn', error);
        return [];
    }
}

export async function optOut(jackpotId: number) {
    try {
        const url = getUrl('fo/jackpot/opt-out');
        return await httpPost(url, { jackpotId });
    } catch (error) {
        logger.error('CasinoThrilltechJackpotsMicroservice', 'optOut', error);
        return [];
    }
}
