import React, { useState } from 'react';
import { isSIM } from '../../../services/environment';
import Wrapper from './styles';
import { translate } from '../../../services/translate';
import { getRoute } from '../../../services/router';
import { stores } from '../../../stores';
import PromotionBonusEntry from '../../promotion/bonus-entry/PromotionBonusEntry';
import { logout } from '../../../services/auth';
import { isFeatureAvailable } from '../../../services/feature';
import UiButton from '../../ui/button/UiButton';
import Svg from '../../svg/Svg';
import ContactUsButton from '../../contact-us-button/ContactUsButton';
import Logo from '../../logo/Logo';
import UiBrandLine from '../../ui/brand-line/UiBrandLine';
import CoolbetHeaderLanguages from '../../coolbet/header/languages/CoolbetHeaderLanguages';
import AuthPayAndPlayButton from '../../auth/pay-and-play/button/AuthPayAndPlayButton';
import AuthRegisterButton from '../../auth/register/button/AuthRegisterButton';
import AuthLoginButton from '../../auth/login/button/AuthLoginButton';
import { isExistingUser } from '../../../services/user';
import { formattedAmountWithCurrency } from '../../../services/currency';
import AccountMenuCurrencySelect from './currency-select/AccountMenuCurrencySelect';
import { useStore } from '../../../hooks/useStore';
import { FEATURE } from '../../../services/types';
import AccountMenuBadgeLabel from './badge-label/AccountMenuBadgeLabel';
import { ONSITE_MESSAGE_TAB } from '../../../microservices/crm/types';

export default function AccountMenu() {
    const [wallet] = useStore(stores.wallet);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [newBlogPostsCount] = useStore(stores.blog.newBlogPostsCount);
    const [messagesByType] = useStore(stores.crm.messagesByType);
    const [dynamicPromotionsCount] = useStore(stores.dynamicPromotionsCount);
    const [isChangingCurrency, setIsChangingCurrency] = useState(false);
    const isSIMLayout = isSIM();
    const isUserExists = isExistingUser();
    const currentBalance = formattedAmountWithCurrency(wallet?.balance_uc || 0);
    const primaryLinks = [
        {
            label: translate('My Bonuses', 'ui.account'),
            route: getRoute('promotions.bonuses'),
            dataTest: 'menu-my-bonuses',
            icon: 'promotions-ico',
            isAvailable: isFeatureAvailable(FEATURE.PROMOTIONS) && !isSIMLayout,
        },
        {
            label: translate('Payout', 'ui.common'),
            route: getRoute('payout'),
            dataTest: 'menu-payout',
            icon: 'withdrawal-ico',
            isAvailable: isFeatureAvailable(FEATURE.WITHDRAWALS),
        },
        {
            label: translate('Deposit', 'ui.common'),
            route: getRoute('deposit'),
            dataTest: 'menu-deposit',
            icon: 'plus-sign',
            isAvailable: isFeatureAvailable(FEATURE.DEPOSIT),
        },
        {
            label: translate('Purchase packages', 'ui.payment-packages'),
            route: getRoute('purchase-packages'),
            dataTest: 'menu-payment-packages',
            icon: 'cash',
            isAvailable: isFeatureAvailable(FEATURE.PAYMENT_PACKAGES),
        },
    ].filter((route) => route.isAvailable);
    const firstLinks = [
        {
            label: translate('My Account', 'ui.header'),
            route: getRoute('account.profile'),
            dataTest: 'menu-my-account',
            icon: 'user-icon',
            isAvailable: isAuthenticated,
        },

        {
            label: (
                <AccountMenuBadgeLabel label={translate('Promotions', 'ui.account')} badge={dynamicPromotionsCount} />
            ),
            route: getRoute('promotions'),
            dataTest: 'menu-my-bonuses',
            icon: 'promotions-ico',
            isAvailable: isFeatureAvailable(FEATURE.PROMOTIONS),
        },

        {
            label: (
                <AccountMenuBadgeLabel
                    label={translate('Inbox', 'ui.account')}
                    badge={messagesByType[ONSITE_MESSAGE_TAB.ALL].unreadMessagesTotal}
                />
            ),
            route: getRoute('inbox'),
            icon: 'sms-icon',
            isAvailable: isFeatureAvailable(FEATURE.INBOX) && isAuthenticated,
        },
    ].filter((route) => route.isAvailable);

    const secondLinks = [
        {
            label: translate('Bet History', 'ui.account'),
            route: getRoute('bet-history.sports'),
            dataTest: 'menu-bet-history',
            icon: 'bethistory-ico',
            isAvailable: isFeatureAvailable(FEATURE.SPORTSBOOK) && isAuthenticated && !isSIMLayout,
        },

        {
            label: translate('Transactions', 'ui.account'),
            route: getRoute('transactions'),
            dataTest: 'menu-transactions',
            icon: 'transactions-ico',
            isAvailable: isAuthenticated,
        },
    ].filter((route) => route.isAvailable);
    const fourthLinks = [
        {
            label: translate('Responsible gaming', 'ui.common'),
            route: getRoute('responsible-gaming.set-limits'),
            dataTest: 'menu-responsible-gaming',
            icon: 'anchor',
            isAvailable: isFeatureAvailable(FEATURE.RESPONSIBLE_GAMING),
        },
        {
            label: translate('Gambling Statistics', 'ui.account'),
            route: getRoute('gambling-statistics'),
            dataTest: 'menu-gambling-statistics',
            icon: 'betting-performance',
            isAvailable: isFeatureAvailable(FEATURE.GAMBLING_STATISTICS) && isAuthenticated && !isSIMLayout,
        },
    ].filter((route) => route.isAvailable);

    const fifthLinks = [
        {
            label: translate('Refer a friend', 'ui.account'),
            route: getRoute('refer-a-friend'),
            dataTest: 'menu-refer-a-friend',
            icon: 'refer-a-friend-ico',
            isAvailable: isFeatureAvailable(FEATURE.REFER_A_FRIEND),
        },
        {
            label: <AccountMenuBadgeLabel label={translate('Blog', 'ui.account')} badge={newBlogPostsCount} />,
            route: getRoute('blog'),
            icon: 'blog-ico',
            isAvailable: isFeatureAvailable(FEATURE.BLOG) && !isSIMLayout,
        },
        {
            route: isSIMLayout ? getRoute('sim.about') : getRoute('about.about-us'),
            label: translate('About us', 'ui.about'),
            icon: 'info',
            isAvailable: isFeatureAvailable(FEATURE.ABOUT),
        },
    ].filter((route) => route.isAvailable);

    function checkSelectedRoute(link) {
        const linkRoute = link.split('/')[2];
        const currentRoute = window.location.pathname.split('/')[2];
        return linkRoute === currentRoute;
    }

    return (
        <Wrapper>
            <div className="account-menu-branding">
                <div className="brand-logo">
                    <Logo size={30} />
                    {isFeatureAvailable(FEATURE.LANGUAGE_SELECTION) && (
                        <div className="info-box">
                            <div className="info-box-content">
                                <CoolbetHeaderLanguages />
                            </div>
                        </div>
                    )}
                </div>
                <UiBrandLine fullWidth />
            </div>

            {isAuthenticated && (
                <div className="account-info" onClick={() => setIsChangingCurrency(true)}>
                    {isSIMLayout ? (
                        <div className="sim-balance-info">
                            <label>{translate('Current balance', 'ui.common')}</label>
                            <div className="current-balance">{currentBalance}</div>
                        </div>
                    ) : (
                        <div className="info-box">
                            <div className="info-box-content balance">{currentBalance}</div>
                        </div>
                    )}
                </div>
            )}

            <div className="account-menu-list">
                {isAuthenticated && (
                    <div className="account-links-primary">
                        {primaryLinks.filter(Boolean).map((link) => (
                            <UiButton
                                url={link.route}
                                key={link.route}
                                type="card"
                                size="small"
                                isOnSurface
                                block
                                data-test={link.dataTest}
                                icon={<Svg icon={link.icon} />}
                                selected={checkSelectedRoute(link.route)}
                            >
                                {link.label}
                            </UiButton>
                        ))}
                    </div>
                )}

                {isAuthenticated && (
                    <div className="account-links">
                        <PromotionBonusEntry hasValidation />
                    </div>
                )}
                <div className="account-links">
                    {firstLinks.filter(Boolean).map((link) => (
                        <UiButton
                            url={link.route}
                            key={link.route}
                            type="menu"
                            data-test={link.dataTest}
                            icon={<Svg icon={link.icon} />}
                            selected={checkSelectedRoute(link.route)}
                        >
                            {link.label}
                        </UiButton>
                    ))}
                </div>

                {isAuthenticated && (
                    <div className="account-links">
                        {secondLinks.filter(Boolean).map((link) => (
                            <UiButton
                                url={link.route}
                                key={link.route}
                                type="menu"
                                data-test={link.dataTest}
                                icon={<Svg icon={link.icon} />}
                                selected={checkSelectedRoute(link.route)}
                            >
                                {link.label}
                            </UiButton>
                        ))}
                    </div>
                )}

                <div className="account-links">
                    <UiButton url={getRoute('support')} type="menu" icon={<Svg icon="support-ico" />}>
                        {translate('Help', 'ui.common')}
                    </UiButton>
                    <ContactUsButton />
                </div>

                <div className="account-links">
                    {fourthLinks.filter(Boolean).map((link) => (
                        <UiButton
                            url={link.route}
                            key={link.route}
                            type="menu"
                            data-test={link.dataTest}
                            icon={<Svg icon={link.icon} />}
                            selected={checkSelectedRoute(link.route)}
                        >
                            {link.label}
                        </UiButton>
                    ))}
                </div>

                <div className="account-links">
                    {fifthLinks.filter(Boolean).map((link) => (
                        <UiButton
                            url={link.route}
                            key={link.route}
                            type="menu"
                            data-test={link.dataTest}
                            icon={<Svg icon={link.icon} />}
                            selected={checkSelectedRoute(link.route)}
                        >
                            {link.label}
                        </UiButton>
                    ))}
                </div>
            </div>
            {isAuthenticated && (
                <UiButton block onClick={() => logout(undefined, false, true)}>
                    {translate('Logout', 'ui.header')}
                </UiButton>
            )}
            {!isAuthenticated && (
                <div className="auth-buttons">
                    {isUserExists ? <AuthPayAndPlayButton /> : <AuthRegisterButton block />}
                    <AuthLoginButton block />
                </div>
            )}
            <AccountMenuCurrencySelect isOpen={isChangingCurrency} onClose={() => setIsChangingCurrency(false)} />
        </Wrapper>
    );
}
