import clone from 'lodash/clone';
import remove from 'lodash/remove';
import map from 'lodash/map';
import partition from 'lodash/partition';
import some from 'lodash/some';
import { parse } from 'query-string';
import React, { SetStateAction, useEffect, useRef, useState } from 'react';

import { withErrorBoundary } from '../../../higher-order-components/error-boundary';
import { getMarketInfoByOutcomeId } from '../../../microservices/sbgate';
import { logger } from '../../../services/logger';
import {
    copyTicketToBetslipByOutcomeIds,
    deleteBetSlip,
    hasLiveMatchesInBetslip,
    navigateToBetslip,
    storeAndClearSelection,
} from '../../../services/sports/betslip';
import { removeAllNonFrontendErrors } from '../../../services/sports/betslip-errors';
import { initialBetSlipPlacingState, initialBetSlipUserState } from '../../../services/sports/constants';
import { storageGet, storageSet } from '../../../services/storage';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import UiFormInput from '../../ui/form/input/UiFormInput';
import SportBonusBetBetslipValidations from '../bonus-bet/betslip-validations/SportBonusBetBetslipValidations';
import SportCampaignsBetslipValidations from '../campaigns/betslip-validations/SportCampaignsBetslipValidations';
import SportBetslipButtonAndErrors from './button-and-errors/SportBetslipButtonAndErrors';
import BetSlipHeader from './header/SportBetslipHeader';
import SportBetslipMarket from './market/SportBetslipMarket';
import SportBetslipReceipt from './receipt/SportBetslipReceipt';
import SportBetslipStakeInput from './stake-input/SportBetslipStakeInput';
import Wrapper from './styles';
import SportBetslipSystemStakeInput from './system-stake-input/SportBetslipSystemStakeInput';
import SportBetslipValidations from './validations/SportBetslipValidations';
import isEmpty from 'lodash/isEmpty';
import SportBetslipBonuses from './bonuses/SportBetslipBonuses';
import UiButton from '../../ui/button/UiButton';
import Svg from '../../svg/Svg';
import { useSportsUserSettings } from '../../../services/sports/user-settings';
import { usePrevious, useSecondaryEffect } from '../../../services/hooks';
import { isMobile } from '../../../services/browser';
import SportBetslipKeepSelections from './keep-selections/SportBetslipKeepSelections';
import cloneDeep from 'lodash/cloneDeep';
import SportBetslipTeasersValidation from './teasers-validation/SportBetslipTeasersValidation';
import uniq from 'lodash/uniq';
import SportBetslipTeaserSelect from './teaser-select/SportBetslipTeaserSelect';
import { isFeatureAvailable } from '../../../services/feature';
import { isBetslipCashoutEligible } from '../../../microservices/bets';
import { isBYOD } from '../../../services/environment';
import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import WagerLimitLink from '../../wager-limit-link/WagerLimitLink';
import { loadOfferedBonuses } from '../../../services/sports/bonus-bets';
import { formattedAmountWithCurrency } from '../../../services/currency';
import { useStore } from '../../../hooks/useStore';
import UiBlobLoader from '../../ui/blob-loader/UiBlobLoader';
import { FEATURE } from '../../../services/types';
import SportBetslipUiEmpty from './ui/empty/SportBetslipUiEmpty';
import SportBetslipUiClosed from './ui/closed/SportBetslipUiClosed';
import ByodBringYourDevice from '../../byod/bring-your-device/ByodBringYourDevice';
import { BET_TYPE, BetslipMode } from '../../../services/sports/types';
import SportBetslipShareButton from './share-button/SportBetslipShareButton';
import { getBetbuilderBetPrice, getBetBuilderIdsByMarketIds } from '../../../microservices/openbet';
import { loadOddsByMarketIds } from '../../../microservices/sb-odds';

function SportBetslip() {
    const [userState, setUserState] = useStore(stores.sports.betSlipUserState);
    const [betSlipPlacingState, setBetSlipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [marketInfoById] = useStore(stores.sports.marketInfoById);
    const [betSlipMarketIdToOutcomeId, setBetSlipMarketIdToOutcomeId] = useStore(
        stores.sports.betSlipMarketIdToOutcomeId,
    );
    const [isTeasersAvailable, setIsTeasersAvailable] = useStore(stores.sports.isTeasersAvailable);
    const [betSlipMarketIds, setBetSlipMarketIds] = useState<string[]>([]); // for persisting the order of markets
    const [comboBetSlipMarketIds, setComboBetSlipMarketIds] = useState<string[]>([]);
    const [totalStakes, setTotalStakes] = useState(0); // for persisting the order of markets
    const [isAllMarketsForTheSameMatch, setIsAllMarketsForTheSameMatch] = useState(false);
    const [isNormalAndOutrightInSameCategory, setIsNormalAndOutrightInSameCategory] = useState(false);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const { betType, acceptAnyOddsChanges, stakeByMarketId, userBetTypeSelection } = userState;
    const { receiptById, isLoading } = betSlipPlacingState;
    const [campaigns, setCampaigns] = useStore(stores.sports.campaigns);
    const { layout } = useSportsUserSettings();
    const clearCopiedFrom = () => setUserState((state) => ({ ...state, copiedFrom: null }));
    const [betslipMarketCategoryIds, setBetslipMarketCategoryIds] = useState<number[]>([]);
    const marketsCount = Object.keys(betSlipMarketIdToOutcomeId).length;
    const [betslipCollection] = useStore(stores.sports.betslipCollection);
    const [betSlipPosition] = useStore(stores.sports.betSlipPosition);
    const previousValues = useRef({ betSlipPosition });
    const isPrevEnabled = Boolean(betslipCollection.length && betSlipPosition !== 0);
    const isNextEnabled = Boolean(betslipCollection.length && betSlipPosition < betslipCollection.length - 1);
    const isPositionChanged = previousValues.current.betSlipPosition !== betSlipPosition;
    const setSingleBetType = () => setUserState((userState) => ({ ...userState, betType: BET_TYPE.SINGLE }));
    const [isCashoutEligible, setIsCashoutEligible] = useState(true);
    const previousBetSlipMarketIds = usePrevious(betSlipMarketIds);
    const { isAmericanBetslipInputFormat } = useSportsUserSettings();
    const [limits] = useStore(stores.sports.limits);
    const [maintenance] = useStore(stores.maintenance.sportsbookMaintenanceState);
    const isBetslipSharingAvailable =
        isFeatureAvailable(FEATURE.BETSLIP_SHARE) && Boolean(marketsCount) && isAuthenticated;
    const [betbuilderMarketsByMatch, setBetbuilderMarketsByMatch] = useStore(
        stores.sports.customBetbuilder.marketsByMatch,
    );
    const [priceRequestResponseByMatch, setPriceRequestResponseByMatch] = useStore(
        stores.sports.customBetbuilder.priceRequestResponseByMatchId,
    );
    const isOpenbetAvailable = Boolean(storageGet('development.custom-betbuilder'));

    useEffect(() => {
        const { outcomeId, outcomeIds } = parse(window.location.search);
        if (outcomeId) {
            copyTicketToBetslipByOutcomeIds([outcomeId], null);
        }
        if (outcomeIds) {
            copyTicketToBetslipByOutcomeIds(outcomeIds.toString().split(',').filter(Number).map(Number), null);
        }
        if (betSlipMarketIds.length > 1) {
            setUserState((userState) => ({
                ...userState,
                betType: BET_TYPE.COMBO,
            }));
        }
    }, []);

    useSecondaryEffect(() => {
        Object.keys({ ...betSlipMarketIdToOutcomeId, ...marketInfoById }).forEach(getMarketInfoById);
    }, [layout, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            isBetslipCashoutEligible(betType, Object.keys(betSlipMarketIdToOutcomeId)).then(setIsCashoutEligible);
        }
    }, [betSlipMarketIdToOutcomeId, betType, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            return;
        }
        setUserState(cloneDeep({ ...initialBetSlipUserState, userBetTypeSelection }));
    }, [isAuthenticated]);

    useEffect(() => {
        setTotalStakes(getTotalSinglesStake() as SetStateAction<number>);
    }, [stakeByMarketId, betSlipMarketIdToOutcomeId]);

    useEffect(removeAllNonFrontendErrors, [betType]);

    async function onBetslipSelectionsListChange() {
        storageSet('betSlipSettings', {
            betSlipMarketIdToOutcomeId,
            updatedAt: new Date().getTime(),
        });
        if (isLoading) {
            setBetSlipMarketIds(Object.keys(betSlipMarketIdToOutcomeId));
            return;
        }
        const marketIds = Object.keys(betSlipMarketIdToOutcomeId);
        const newMarketIds = clone(betSlipMarketIds);
        const addedMarkets = marketIds.filter((marketId) => !newMarketIds.includes(marketId));
        newMarketIds.push(...addedMarkets);
        const removedMarkets = remove(newMarketIds, (marketId) => !marketIds.includes(marketId));

        const getMarketInfoPromises = addedMarkets
            .filter((market) => !marketInfoById[market])
            .map((marketId) => getMarketInfoById(marketId));
        await Promise.all(getMarketInfoPromises);
        await checkForBetbuilderBetType();

        if (addedMarkets.concat(removedMarkets).length) {
            removeAllNonFrontendErrors();
            setBetSlipMarketIds(newMarketIds);
            setBetSlipPlacingState(initialBetSlipPlacingState);
            setUserState((state) => {
                state.MAStakeByMarketId = {};
                state.MASystemStakes = {};
            });
        }

        if (isBYOD() && isPositionChanged) {
            previousValues.current.betSlipPosition = betSlipPosition;
            return;
        }

        const tempCampaigns = { ...campaigns };
        delete tempCampaigns.selected;

        setCampaigns(tempCampaigns);

        if (removedMarkets.length && !newMarketIds.length) {
            setUserState((userState) => ({ ...userState, userBetTypeSelection: undefined }));
        }

        if (betType === BET_TYPE.TEASER) {
            if (removedMarkets.length && marketIds.length < 2) {
                setSingleBetType();
            }
            return;
        }

        const hasBetBuilderMarketInBetslip = checkBetbuilderMarketIsInBetslip();

        if (hasBetBuilderMarketInBetslip) {
            // TODO: remove when deep integration will be prod ready
            let betType = newMarketIds.length >= 2 ? BET_TYPE.COMBO : BET_TYPE.SINGLE;

            if (isOpenbetAvailable) {
                betType = BET_TYPE.COMBO;
            }
            setUserState((userState) => ({
                ...userState,
                betType,
            }));
        } else if (newMarketIds.length >= 2 && betType !== BET_TYPE.SYSTEM) {
            let betType;
            if (userBetTypeSelection) {
                betType = userBetTypeSelection;
            } else if (isAllMarketsForTheSameMatch) {
                betType = checkComboAllowedForSameMatch() ? BET_TYPE.COMBO : BET_TYPE.SINGLE;
            } else {
                betType = isNormalAndOutrightInSameCategory ? BET_TYPE.SINGLE : BET_TYPE.COMBO;
            }

            setUserState((userState) => ({
                ...userState,
                betType,
            }));
        } else if (removedMarkets.length) {
            if (newMarketIds.length > 2) {
                setUserState((userState) => ({ ...userState, betType: userBetTypeSelection || BET_TYPE.SYSTEM }));
            } else if (newMarketIds.length === 2) {
                setUserState((userState) => ({
                    ...userState,
                    betType: BET_TYPE.COMBO,
                    userBetTypeSelection: BET_TYPE.COMBO,
                }));
            } else {
                setSingleBetType();
            }
        }
    }

    useEffect(() => {
        onBetslipSelectionsListChange();
    }, [betSlipMarketIdToOutcomeId, isAllMarketsForTheSameMatch, isNormalAndOutrightInSameCategory, marketInfoById]);

    useEffect(() => {
        checkBetslipCategories(betSlipMarketIds, marketInfoById);
        checkForSameMatchInBetslip(betSlipMarketIds);
        checkForSameCategoryNormalAndOutright();
        checkIfTeasersAvailable(betSlipMarketIds);

        if (
            isAuthenticated &&
            isFeatureAvailable(FEATURE.BONUSES) &&
            isEmpty(previousBetSlipMarketIds) &&
            !isEmpty(betSlipMarketIds)
        ) {
            loadOfferedBonuses();
        }
    }, [betSlipMarketIds, marketInfoById]);

    useEffect(() => {
        setComboBetSlipMarketIds([]);
        let comboMarkets = [...betSlipMarketIds];
        Object.entries(betbuilderMarketsByMatch).forEach(([matchId, outcomeIds]) => {
            const betbuilderPriceResult = priceRequestResponseByMatch[matchId];
            if (!betbuilderPriceResult) {
                return;
            }
            comboMarkets = [
                ...comboMarkets.filter((marketId) => {
                    const shouldShowMarketInComboBetSlip =
                        !outcomeIds.includes(betSlipMarketIdToOutcomeId[marketId]) ||
                        betbuilderPriceResult.redundantOutcomeIds.includes(betSlipMarketIdToOutcomeId[marketId]);
                    if (shouldShowMarketInComboBetSlip) {
                        return true;
                    }
                }),
                betbuilderPriceResult.market_id.toString(),
            ];
        });
        setComboBetSlipMarketIds(comboMarkets);
    }, [betSlipMarketIds, priceRequestResponseByMatch, betSlipMarketIdToOutcomeId, betbuilderMarketsByMatch]);

    function checkComboAllowedForSameMatch() {
        const marketIdsInBetslip = Object.keys(betSlipMarketIdToOutcomeId);
        return (
            Boolean(marketIdsInBetslip.length) &&
            Object.values(marketInfoById)
                .filter((market) => marketIdsInBetslip.includes(market.id.toString()))
                .every((market) => market.same_match_combo_allowed)
        );
    }

    function checkBetbuilderMarketIsInBetslip() {
        const marketIdsInBetslip = Object.keys(betSlipMarketIdToOutcomeId);

        const betbuilderMarketsWithInfoInBetslip = Object.values(marketInfoById ?? {}).filter(
            (market) => marketIdsInBetslip.includes(market.id.toString()) && market.view_type === 'bet_builder',
        );

        return Boolean(betbuilderMarketsWithInfoInBetslip.length);
    }

    function checkBetslipCategories(marketIds: string[], marketInfoById: Record<number, BetSlipMinimalMarket>) {
        const categoryIds = uniq(
            marketIds.map((marketIdString) => marketInfoById?.[Number(marketIdString)]?.category_id).filter(Boolean),
        );
        setBetslipMarketCategoryIds(categoryIds);
        return categoryIds;
    }

    function checkIfTeasersAvailable(marketIds: string[]) {
        if (!isFeatureAvailable(FEATURE.TEASERS)) {
            return;
        }

        const areSelectedMarketsTeasable = marketIds.every((marketIdString) => {
            // if marketInfoById hasn't the newly added market loaded yet, then dont disable teasers right away
            const market = marketInfoById?.[Number(marketIdString)];
            if (!market) {
                return true;
            }
            return market.teasers_enabled && !market.in_play;
        });
        const _isTeasersAvailable = marketIds.length > 1 && areSelectedMarketsTeasable;
        if (isTeasersAvailable && !_isTeasersAvailable) {
            setIsTeasersAvailable(false);
        } else if (!isTeasersAvailable && _isTeasersAvailable) {
            setIsTeasersAvailable(true);
        }
    }

    async function getMarketInfoById(marketId) {
        if (!marketId) {
            return;
        }

        const outcomeId = betSlipMarketIdToOutcomeId[marketId];

        if (!outcomeId) {
            return;
        }
        try {
            await getMarketInfoByOutcomeId(outcomeId);
        } catch (error) {
            logger.error('SportBetslip', 'getMarketInfoById', error);
            setBetSlipMarketIdToOutcomeId((state) => {
                delete state[marketId];
            });
        }
    }

    function getTotalSinglesStake() {
        const allSinglesStakes: any[] = Object.keys(betSlipMarketIdToOutcomeId).map((marketId) => {
            const stake = stakeByMarketId[marketId];
            return stake;
        });

        return allSinglesStakes.reduce((acc: number, value) => acc + parseFloat(value || 0), 0);
    }

    function checkForSameMatchInBetslip(marketIds: string[]) {
        const firstMatchId = marketInfoById[marketIds[0]] ? marketInfoById[marketIds[0]].match_id : false;
        const isSame = marketIds.every(
            (marketId) => firstMatchId === (marketInfoById[marketId] ? marketInfoById[marketId].match_id : false),
        );
        setIsAllMarketsForTheSameMatch(Boolean(isSame));
    }

    function checkForSameCategoryNormalAndOutright() {
        const hasBothOutrightAndNormalMatches = new Set(map(marketInfoById, (market) => market.match_type)).size > 1;
        let isSameCategory = false;
        if (hasBothOutrightAndNormalMatches) {
            const [outrights, normals] = partition(marketInfoById, (market) => market.match_type === 'OUTRIGHT');
            isSameCategory = some(outrights, (outright) =>
                some(normals, (normal) => outright.category_id === normal.category_id),
            );
        }
        setIsNormalAndOutrightInSameCategory(Boolean(isSameCategory));
    }

    async function checkForBetbuilderBetType() {
        const betbuilderOutcomeIdsByMatchId: Record<string, number[]> = {};
        const marketsToGetBetbuilderId: string[] = [];
        Object.entries(betSlipMarketIdToOutcomeId).forEach(([marketId, outcomeId]) => {
            const marketInfo = marketInfoById[marketId];
            if (!marketInfo) {
                return;
            }
            if (marketInfo.betbuilder_eligible) {
                betbuilderOutcomeIdsByMatchId[marketInfo.match_id] = [
                    ...(betbuilderOutcomeIdsByMatchId[marketInfo.match_id] ?? []),
                    outcomeId,
                ];
            }
            if (marketInfo.view_type === 'bet_builder') {
                marketsToGetBetbuilderId.push(marketId);
            }
        });

        const betbuilderMarketToRequestPrice = Object.entries(betbuilderOutcomeIdsByMatchId).filter(
            ([, outcomeIds]) => outcomeIds.length >= 2,
        );
        setPriceRequestResponseByMatch({});
        if (betbuilderMarketToRequestPrice.length) {
            const betbuilderMarketPromises = betbuilderMarketToRequestPrice.map(([matchId, outcomeIds]) =>
                getBetbuilderBetPrice(parseInt(matchId, 10), outcomeIds, true, 1),
            );
            const betbuilderRequestResponses = await Promise.all(betbuilderMarketPromises);
            betbuilderRequestResponses.forEach(async (response, index) => {
                if (response) {
                    const marketInfoResponse = await getMarketInfoByOutcomeId(Number(response.outcome_id));
                    const { market_id, outcome_id, betbuilder_id } = response;
                    const { match_id } = marketInfoResponse;
                    stores.sports.betbuilderBetslipIdByMarketId.set((state) => {
                        if (betbuilder_id) {
                            state[market_id] = betbuilder_id;
                        }
                        return state;
                    });
                    const allMatchMarketIds = Object.entries(marketInfoById)
                        .filter(([, value]) => {
                            return value.match_id === match_id;
                        })
                        .map(([key]) => key);

                    stores.sports.betSlipMarketIdToOutcomeId.set((state) => {
                        allMatchMarketIds.forEach((marketId) => {
                            delete state[marketId];
                        });
                        state[market_id.toString()] = outcome_id;
                        return state;
                    });
                    loadOddsByMarketIds(response.market_id);
                    setPriceRequestResponseByMatch((prev) => {
                        prev[betbuilderMarketToRequestPrice[index][0]] = response;
                        return prev;
                    });
                }
            });
        }
        const betbuilderIdsByMarketIds = await getBetBuilderIdsByMarketIds(marketsToGetBetbuilderId);
        betbuilderIdsByMarketIds.map((mapping) => {
            stores.sports.betbuilderBetslipIdByMarketId.set((state) => {
                state[mapping.market_id] = mapping.betbuilder_id;
                return state;
            });
        });
        setBetbuilderMarketsByMatch(Object.fromEntries(betbuilderMarketToRequestPrice));
    }

    function clearSelection() {
        if (isLoading) {
            return;
        }
        setUserState(initialBetSlipUserState);
        storageSet('betSlipSettings', {
            betSlipMarketIdToOutcomeId: {},
            updatedAt: new Date().getTime(),
        });
        setBetSlipMarketIdToOutcomeId({});
        stores.sports.betSlipErrorByMarketId.set({});
        clearCopiedFrom();
        if (isMobile()) {
            window.history.go(-1);
        }
    }

    const receiptMarketIds = Object.keys(receiptById);
    const hasLiveMatches = hasLiveMatchesInBetslip();
    const setAcceptAnyOddsChanges = (value) => setUserState((state) => ({ ...state, acceptAnyOddsChanges: value }));
    const multipleSingles = [BET_TYPE.SINGLE].includes(betType) && Object.keys(betSlipMarketIdToOutcomeId).length > 1;

    if (!limits) {
        return <UiBlobLoader />;
    }

    return (
        <Wrapper $isAmericanBetslipInputFormat={isAmericanBetslipInputFormat}>
            {isEmpty(betSlipMarketIdToOutcomeId) && isEmpty(receiptById) && !maintenance.betslip && (
                <SportBetslipUiEmpty />
            )}

            <div className="sports-betslip animated fadeIn">
                {!isEmpty(betSlipMarketIdToOutcomeId) && <BetSlipHeader clearSelection={clearSelection} />}
                {betType === BET_TYPE.TEASER && <SportBetslipTeaserSelect />}
                {receiptMarketIds.map((marketId) => (
                    <SportBetslipReceipt key={marketId} marketId={marketId} />
                ))}

                {!isEmpty(betSlipMarketIdToOutcomeId) && (
                    <div className="betslip-selections">
                        {(userState.betType === BET_TYPE.COMBO && Object.values(priceRequestResponseByMatch).length
                            ? comboBetSlipMarketIds
                            : betSlipMarketIds
                        )
                            .filter((marketId) => !receiptMarketIds.includes(marketId))
                            .map((marketId) => (
                                <SportBetslipMarket marketId={marketId} key={marketId} />
                            ))}
                    </div>
                )}

                <SportBetslipValidations />

                <div>
                    {!receiptMarketIds.length && [BET_TYPE.COMBO, BET_TYPE.TEASER].includes(betType) && (
                        <SportBetslipStakeInput marketId={null} />
                    )}
                    {betType === BET_TYPE.SYSTEM && (
                        <SportBetslipSystemStakeInput isPositionChanged={isPositionChanged} />
                    )}
                    {multipleSingles && (
                        <div className="total-stake-singles">
                            {translate('Total stakes', 'ui.sportsbook')} →
                            <span className="total-stake-amount">{formattedAmountWithCurrency(totalStakes)}</span>
                        </div>
                    )}
                    {maintenance.betslip ? (
                        <SportBetslipUiClosed />
                    ) : (
                        !isEmpty(betSlipMarketIdToOutcomeId) && (
                            <>
                                <SportBetslipButtonAndErrors clearSelection={clearSelection} />
                                {isCashoutEligible && isFeatureAvailable(FEATURE.PLACE_BET) && !isBYOD() && (
                                    <div className="cashout-status">
                                        <span>{translate('Cashout', 'ui.betslip')}</span>
                                        <Svg icon="check" size={0.75} />
                                    </div>
                                )}
                                {isFeatureAvailable(FEATURE.WAGER_LIMIT_LINK) && <WagerLimitLink />}
                            </>
                        )
                    )}
                    <div className="alerts-container">
                        {!maintenance.betslip && <SportBetslipBonuses betSlipMarketIds={betSlipMarketIds} />}
                        <SportCampaignsBetslipValidations />
                        <SportBonusBetBetslipValidations />
                        {isTeasersAvailable && (
                            <SportBetslipTeasersValidation
                                betSlipMarketIds={betSlipMarketIds}
                                betSlipOutcomeIds={Object.values(betSlipMarketIdToOutcomeId)}
                                categoryIds={betslipMarketCategoryIds}
                            />
                        )}
                    </div>

                    {!maintenance.betslip && isFeatureAvailable(FEATURE.BETSLIP_KEEP_SELECTIONS) && (
                        <SportBetslipKeepSelections />
                    )}
                    {hasLiveMatches && !maintenance.betslip && (
                        <UiFormInput
                            name="acceptOddsChanges"
                            checkbox
                            disabled={isLoading}
                            label={translate('Accept any odds changes', 'ui.sportsbook')}
                            onValueChange={setAcceptAnyOddsChanges}
                            value={acceptAnyOddsChanges}
                            className="accept-odds-change"
                        />
                    )}

                    {isBetslipSharingAvailable && (
                        <SportBetslipShareButton
                            outcomeIds={Object.values(betSlipMarketIdToOutcomeId)}
                            betType={userState.betType}
                        />
                    )}

                    {isBYOD() && (
                        <>
                            {!isEmpty(betSlipMarketIdToOutcomeId) && isEmpty(receiptById) && (
                                <ByodBringYourDevice betslipMode={BetslipMode.Betslip} />
                            )}
                            <div className="qr-code-nav">
                                {isPrevEnabled ? (
                                    <Svg
                                        icon="drawer"
                                        size={1}
                                        onClick={() => navigateToBetslip(betSlipPosition - 1, BetslipMode.Betslip)}
                                        className="drawer-icon"
                                    />
                                ) : (
                                    <div className="drawer-icon" />
                                )}
                                {Boolean(betslipCollection.length) && (
                                    <div className="pagination-counter">
                                        {betSlipPosition + 1} / {betslipCollection.length}
                                    </div>
                                )}
                                {isNextEnabled ? (
                                    <Svg
                                        icon="drawer"
                                        rotate={180}
                                        size={1}
                                        onClick={() => navigateToBetslip(betSlipPosition + 1, BetslipMode.Betslip)}
                                        className="drawer-icon"
                                    />
                                ) : (
                                    <div className="drawer-icon" />
                                )}
                            </div>
                            {
                                <>
                                    {Boolean(marketsCount) && (
                                        <UiButton
                                            className="action-btn"
                                            color="primary"
                                            size="large"
                                            block
                                            onClick={() => storeAndClearSelection(BetslipMode.Betslip)}
                                            disabled={isLoading}
                                        >
                                            {translate('Add New', 'ui.sportsbook')}
                                        </UiButton>
                                    )}
                                    {Boolean(betslipCollection.length) && (
                                        <UiButton
                                            size="large"
                                            block
                                            className="action-btn"
                                            onClick={() => deleteBetSlip(BetslipMode.Betslip)}
                                            disabled={isLoading}
                                        >
                                            {translate('Delete', 'ui.sportsbook')}
                                        </UiButton>
                                    )}
                                </>
                            }
                        </>
                    )}
                </div>
            </div>
        </Wrapper>
    );
}

export default withErrorBoundary(React.memo(SportBetslip));
