import React, { useMemo } from 'react';
import Wrapper from './styles';
import { Country } from '@staycool/location';
import { isB2B } from '../../../services/environment';
import { filterStyleProps } from '../../../styles/utils';

interface Props {
    flag: string;
    squared?: boolean;
    size?: number;
    className?: string;
    onClick?: () => void;
    shouldForceRenderFlag?: boolean;
}
export default function UiFlag({ flag = '', squared, shouldForceRenderFlag = false, size, ...rest }: Props) {
    // TODO: find a better solution
    const pngFlags = [
        'wot',
        'sf',
        'quake',
        'hs',
        'hots',
        'fifa',
        'aov',
        'cod',
        'bo',
        'sc1',
        'ow',
        'nba2k',
        'kog',
        'dota',
        'lol',
        'csgo',
        'rbsx',
        'kog',
        'sc2',
        'overw',
        'arfct',
        'rle',
        'int',
        'asi',
        'afr',
        'americas',
        'copa-america',
        'north-america',
        'eur2021',
        'valorant',
    ];
    const imagePath = useMemo(() => {
        const country = flag && flag.toLowerCase();
        const shouldDisplayEuropeanFlag =
            !isB2B() && country === Country.NORWAY.toLowerCase() && !shouldForceRenderFlag;
        let path = `${window.coolb2b?.baseUrl || ''}/assets/images/flags/${squared ? '1x1' : '4x3'}/${
            shouldDisplayEuropeanFlag ? 'eu' : country
        }`;
        path += pngFlags.includes(country) ? '.png' : '.svg';
        return path;
    }, [flag, squared]);

    if (!flag) {
        return null;
    }

    return <Wrapper {...filterStyleProps({ ...rest })} $size={size} src={imagePath} alt="" />;
}
