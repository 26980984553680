import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { isMobile } from '../../../../services/browser';
import {
    getErrorMessage,
    getMarketErrorStatusByErrorsArray,
    isInfo,
    isWarning,
} from '../../../../services/sports/betslip';
import {
    addBetslipError,
    removeAllNonFrontendErrors,
    removeBetslipError,
} from '../../../../services/sports/betslip-errors';
import { MATCH_TYPE, ODDS_CHANGED_ERROR, ODDS_CLOSED_ERRORS_BACKEND } from '../../../../services/sports/constants';
import { useBetslipOddsByOutcomeId, useMatchStatusUpdateSubscribe } from '../../../../services/sports/hooks';
import { stores, useStoreWithSelector } from '../../../../stores';
import Loader from '../../../loader/Loader';
import UiAlert from '../../../ui/alert/UiAlert';
import SportBetslipOutcomes from '../outcomes/SportBetslipOutcomes';
import Wrapper from './styles';
import { useSecondaryEffect } from '../../../../services/hooks';
import { getMarketInfoByOutcomeId } from '../../../../microservices/sbgate';
import { logger } from '../../../../services/logger';
import { getLine } from '../../../../services/sports/market';
import Svg from '../../../svg/Svg';
import SportOddsLoad from '../../odds/load/SportOddsLoad';
import { isFeatureAvailable } from '../../../../services/feature';
import UiAnimate from '../../../ui/animate/UiAnimate';
import { BetSlipMinimalMarket } from '@staycool/sbgate-types';
import { useStore } from '../../../../hooks/useStore';
import { FEATURE } from '../../../../services/types';
import { BET_TYPE } from '../../../../services/sports/types';
import { useStakePerBetSetting } from '../../../../hooks/betslip-v2';
import { isTestUser } from '../../../../services/user';
import SportBetslipStakeInput from '../stake-input/SportBetslipStakeInput';
import SportBetslipV2StakeInput from '../../betslip-v-2/stake-input/SportBetslipV2StakeInput';

interface Props {
    marketId: string;
}

export default function SportBetslipMarket({ marketId }: Props) {
    const [marketInfo] = useStoreWithSelector(stores.sports.marketInfoById, (state) => state[Number(marketId)], [
        marketId,
    ]);
    const [outcomeId] = useStoreWithSelector(stores.sports.betSlipMarketIdToOutcomeId, (state) => state[marketId], [
        marketId,
    ]);
    const [{ betType }] = useStore(stores.sports.betSlipUserState);
    const [betslipPlacingState] = useStore(stores.sports.betSlipPlacingState);
    const [betSlipErrorByMarketId] = useStore(stores.sports.betSlipErrorByMarketId);
    const [teaserSelectedPoint] = useStore(stores.sports.teaserSelectedPoint);
    const [teaserPayouts] = useStore(stores.sports.teaserPayouts);
    const { receiptById, isLoading } = betslipPlacingState;
    const currentMarketBetslipErrors = betSlipErrorByMarketId[marketId] || [];
    const [isOddsSelectOn, setIsOddsSelectOn] = useState(false);
    const isLayoutB = !isFeatureAvailable(FEATURE.SPORT_LAYOUT_A);
    const { isBetslipWarning, isBetslipError } = getMarketErrorStatusByErrorsArray(currentMarketBetslipErrors);
    const { isOddsValueChanged, isOddsAvailable, currentOdds } = useBetslipOddsByOutcomeId(
        outcomeId,
        marketInfo?.in_play,
        marketInfo?.provider,
        false,
    );
    const isBetbuilderMarket = marketInfo?.view_type === 'bet_builder';

    const outcome = useMemo(() => {
        if (isBetbuilderMarket) {
            return marketInfo?.outcomes[0];
        }
        return marketInfo?.outcomes?.find((o) => o.id === outcomeId);
    }, [marketInfo, outcomeId]);

    const alternativePoints = teaserPayouts?.alternative_points?.find(
        (ap) => ap.sport_category_id === marketInfo?.sport_category_id && ap.original_points === teaserSelectedPoint,
    )?.alternative_points;

    useEffect(() => {
        removeBetslipError(marketId, ODDS_CHANGED_ERROR);
    }, [outcomeId]);

    useEffect(() => {
        if (isOddsValueChanged && isOddsAvailable) {
            addBetslipError(marketId, ODDS_CHANGED_ERROR);
        }
    }, [isOddsValueChanged]);

    const matchStatus = useMatchStatusUpdateSubscribe({ id: marketInfo?.match_id, status: marketInfo?.match_status });

    useEffect(() => {
        if (isOddsAvailable) {
            removeBetslipError(marketId, ODDS_CLOSED_ERRORS_BACKEND[0]);
        } else if (currentOdds) {
            addBetslipError(marketId, ODDS_CLOSED_ERRORS_BACKEND[0]);
        }
    }, [isOddsAvailable, outcomeId, currentOdds]);

    useSecondaryEffect(() => {
        getMarketInfoByOutcomeId(outcomeId).catch((error) =>
            logger.error('SportBetslipMarket', 'SportBetslipMarket', error),
        );
    }, [matchStatus]);

    const { stakePerBetSetting } = useStakePerBetSetting();

    if (!marketInfo) {
        return (
            <Wrapper>
                <div className="fake-market">
                    <Loader className="loader" />
                </div>
            </Wrapper>
        );
    }

    function removeMarketFromBetslip() {
        removeAllNonFrontendErrors();
        stores.sports.betSlipMarketIdToOutcomeId.set((state) => delete state[marketId] && state);
        stores.sports.betbuilderBetslipIdByMarketId.set((state) => delete state[marketId] && state);
        stores.sports.marketInfoById.set((state) => delete state[marketId] && state);

        if (Object.entries(stores.sports.betSlipMarketIdToOutcomeId.state).length === 0 && isMobile()) {
            stores.isBetslipOpen.set(false);
        }
    }

    function getBetbuilderMarketName(marketName: string) {
        const markets = marketName.split(' And ').map((market) => market.split(' - '));
        return (
            <div className="betbuilder-markets-list">
                {markets.map(([market, outcome], index) => (
                    <div key={index} className="betbuilder-market">
                        <span>{market}</span>
                        <span className="outcome">{outcome}</span>
                    </div>
                ))}
            </div>
        );
    }

    function getPlayerName({ marketName, marketNameWithoutLine, player_names: playerNames }: BetSlipMinimalMarket) {
        const marketNameToUse = isFeatureAvailable(FEATURE.BETSLIP_FULL_MARKET_NAME)
            ? marketName
            : marketNameWithoutLine || marketName;
        if (playerNames?.length && marketInfo.match_type !== MATCH_TYPE.OUTRIGHT) {
            const [playerName] = playerNames;
            return marketNameToUse?.replace(playerName, `<em>${playerName}</em>`);
        }
        return marketNameToUse;
    }

    function getMarketName(marketInfo: BetSlipMinimalMarket) {
        if (betType === BET_TYPE.TEASER) {
            return marketInfo.marketTypeName;
        }
        return isBetbuilderMarket ? getBetbuilderMarketName(marketInfo.marketName) : getPlayerName(marketInfo);
    }

    return (
        <UiAnimate animationIn="fadeIn" animationOut="fadeOut" isVisible={true} key={marketId}>
            <Wrapper
                data-test="sport-betslip-market"
                className={classnames({ warning: isBetslipWarning, error: isBetslipError })}
                $isLayoutB={isLayoutB}
            >
                {!receiptById[marketId] && (
                    <div>
                        <div className="bet-header">
                            <div className="outcome-info">
                                <div
                                    className="outcome-selection"
                                    onClick={() => setIsOddsSelectOn(isLayoutB ? false : !isOddsSelectOn)}
                                >
                                    {betType !== BET_TYPE.TEASER && outcome && (
                                        <>
                                            {isBetbuilderMarket ? (
                                                <div className="outcome-selection-name bet-builder">
                                                    {marketInfo.match_name}
                                                    <div
                                                        className="outcome-odds"
                                                        onClick={() => setIsOddsSelectOn(!isOddsSelectOn)}
                                                    >
                                                        {outcome && (
                                                            <SportOddsLoad
                                                                isDisabled={isLoading}
                                                                outcomeId={outcome.id}
                                                                market={marketInfo}
                                                                isBetslip
                                                                isOutcomeNameVisible={false}
                                                                isAmericanLayout={false}
                                                                small
                                                            />
                                                        )}
                                                    </div>
                                                    <Svg
                                                        icon="trash"
                                                        size={1}
                                                        onClick={removeMarketFromBetslip}
                                                        className="remove-selection"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="outcome-selection-name">
                                                    {outcome.name} {getLine(marketInfo, outcome, false)}
                                                    <div
                                                        className="outcome-odds"
                                                        onClick={() => setIsOddsSelectOn(!isOddsSelectOn)}
                                                    >
                                                        {outcome && (
                                                            <SportOddsLoad
                                                                isDisabled={isLoading}
                                                                outcomeId={outcome.id}
                                                                market={marketInfo}
                                                                isBetslip
                                                                isOutcomeNameVisible={false}
                                                                isAmericanLayout={false}
                                                                small
                                                            />
                                                        )}
                                                    </div>
                                                    <Svg
                                                        icon="trash"
                                                        size={1}
                                                        onClick={removeMarketFromBetslip}
                                                        className="remove-selection"
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {betType === BET_TYPE.TEASER && outcome && teaserSelectedPoint && (
                                        <div className="teaser-outcome">
                                            <div className="outcome-selection-name">{outcome.name} </div>
                                            <div className="teaser-outcome-line">
                                                <span className="teaser-outcome-original-line">
                                                    {getLine(marketInfo, outcome, false)}
                                                </span>
                                                <span className="teaser-outcome-arrow">{' ›› '}</span>
                                                <span className="teaser-outcome-teased-line">
                                                    {getLine(
                                                        marketInfo,
                                                        outcome,
                                                        false,
                                                        alternativePoints || teaserSelectedPoint,
                                                    )}
                                                </span>
                                            </div>
                                            <Svg
                                                icon="trash"
                                                size={1}
                                                onClick={removeMarketFromBetslip}
                                                className="remove-selection"
                                            />
                                        </div>
                                    )}
                                    {isBetbuilderMarket ? (
                                        <div className="market-name">{getMarketName(marketInfo)}</div>
                                    ) : (
                                        <div
                                            className="market-name"
                                            dangerouslySetInnerHTML={{ __html: getMarketName(marketInfo) as string }}
                                        />
                                    )}
                                </div>
                            </div>

                            {betType !== BET_TYPE.BETBUILDER && !isBetbuilderMarket && (
                                <div className="match-name">{marketInfo.match_name}</div>
                            )}
                        </div>

                        {betType !== BET_TYPE.TEASER && isOddsSelectOn && (
                            <SportBetslipOutcomes marketInfo={marketInfo} isDisabled={isLoading} />
                        )}

                        {[BET_TYPE.SINGLE, BET_TYPE.BETBUILDER].includes(betType) && (
                            <>
                                {stakePerBetSetting && (
                                    <>
                                        {isFeatureAvailable(FEATURE.BETSLIP_V2) && isTestUser() ? (
                                            <SportBetslipV2StakeInput marketId={marketId} />
                                        ) : (
                                            <SportBetslipStakeInput marketId={marketId} />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                )}

                {currentMarketBetslipErrors.map((betslipError) => (
                    <UiAlert
                        className="market-error"
                        info={isInfo(betslipError)}
                        warning={isWarning(betslipError)}
                        failure={!isWarning(betslipError) && !isInfo(betslipError)}
                        key={betslipError}
                    >
                        {getErrorMessage(betslipError)}
                    </UiAlert>
                ))}
            </Wrapper>
        </UiAnimate>
    );
}
