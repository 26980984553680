import { Country } from '@staycool/location';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../../hooks/useStore';
import { authenticateWithEmail, getBackofficeLoginToken, isVisitorFromCountry } from '../../../../services/auth';
import { LoginMethod } from '../../../../services/auth/types';
import { isMobile } from '../../../../services/browser';
import { navigateToCasinoGamePage } from '../../../../services/casino/games';
import { isFeatureAvailable } from '../../../../services/feature';
import { verifyGeoLocation } from '../../../../services/geocomply/geocomply';
import { LocalStorage } from '../../../../services/local-storage/types';
import { POKER_CLIENT_TYPE, getClientType } from '../../../../services/poker';
import { getRoute, useRouter } from '../../../../services/router';
import { storageGet } from '../../../../services/storage';
import { translate } from '../../../../services/translate';
import { FEATURE } from '../../../../services/types';
import { getFieldValidators } from '../../../../services/validators';
import { stores } from '../../../../stores';
import { cams } from '../../../../stores/cams';
import { casino } from '../../../../stores/casino';
import Svg from '../../../svg/Svg';
import Ui2Form from '../../../ui-2/form/Ui2Form';
import Ui2FormTextInput from '../../../ui-2/form/text-input/Ui2FormTextInput';
import UiAlert from '../../../ui/alert/UiAlert';
import UiButton from '../../../ui/button/UiButton';
import UiFormGroup from '../../../ui/form/group/UiFormGroup';
import UiLineSeparator from '../../../ui/line-separator/UiLineSeparator';
import AuthBankId from '../../bank-id/AuthBankId';
import AuthButton from '../../button/AuthButton';
import AuthSmartId from '../../smart-id/AuthSmartId';
import AuthLoginButtonBankId from '../button/bank-id/AuthLoginButtonBankId';
import AuthLoginButtonFacebook from '../button/facebook/AuthLoginButtonFacebook';
import AuthLoginButtonFinnishBankId from '../button/finnish-bank-id/AuthLoginButtonFinnishBankId';
import AuthLoginButtonFinnishMobileId from '../button/finnish-mobile-id/AuthLoginButtonFinnishMobileId';
import AuthLoginButtonGoogle from '../button/google/AuthLoginButtonGoogle';
import AuthLoginButtonSmartId from '../button/smart-id/AuthLoginButtonSmartId';
import AuthLoginForgotPassword from '../forgot-password/AuthLoginForgotPassword';
import AuthLoginRenewPassword from '../renew-password/AuthLoginRenewPassword';
import AuthLoginFormRegistrationLink from './registration-link/AuthLoginFormRegistrationLink';
import Wrapper from './styles';
import AuthLoginButtonApple from '../button/apple/AuthLoginButtonApple';

interface Props {
    isPokerLogin?: boolean;
    loading?: boolean;
    onSuccess?: () => void;
    onClose?: () => void;
}

export default function AuthLoginForm({
    isPokerLogin = false,
    onSuccess = () => {},
    loading = false,
    onClose = () => {},
}: Props) {
    const { navigateTo } = useRouter();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isEmailLoginFormVisible, setIsEmailLoginFormVisible] = useState(Boolean(isEmailLoginVisible()));
    const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(false);
    const [isBankIdLoginVisible, setIsBankIdLoginVisible] = useState(false);
    const [preferredLoginMethod, setPreferredLoginMethod] = useState<LoginMethod>(getPreferredLoginMethod());
    const [isLoginModalOpen] = useStore(stores.modals.isLoginModalOpen);
    const [gamePromisedToNavigate, setGamePromisedToNavigate] = useStore(casino.latestGamePromisedToNavigate);
    const [appealId] = useStore(stores.appeal.id);
    const [, setIsAppealFormOpen] = useStore(stores.appeal.isFormOpen);
    const [, setIsLoginFormOpen] = useStore(stores.modals.isLoginFormOpen);
    const [routePromisedToNavigate] = useStore(casino.latestURLPromisedToNavigate);
    const [isRenewPasswordFormVisible, setIsRenewPasswordFormVisible] = useState(false);
    const isVisitorFromSweden = isVisitorFromCountry(Country.SWEDEN);
    const isVisitorFromEstonia = isVisitorFromCountry(Country.ESTONIA);
    const isVisitorFromFinland = isVisitorFromCountry(Country.FINLAND);

    const useFormMethods = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });
    const { formState } = useFormMethods;
    const device = getClientType();

    const isBoUserLogin = !!getBackofficeLoginToken();
    const isIOSDevice = [POKER_CLIENT_TYPE.IOS, POKER_CLIENT_TYPE.MAC_DOWNLOAD].includes(device);
    const areOtherLoginOptionsAvailable = isFeatureAvailable(FEATURE.OTHER_LOGIN_OPTIONS);

    useEffect(() => {
        if (!isLoginModalOpen) {
            setErrorMessage('');
        }
    }, [isLoginModalOpen]);

    async function startLogin() {
        setErrorMessage('');
        setIsLoading(true);

        const { email, password } = useFormMethods.getValues();
        try {
            const response = await authenticateWithEmail({ email, password });

            if (isFeatureAvailable(FEATURE.LOGIN_GEO_LOCATION) && !isMobile()) {
                await verifyGeoLocation('LOGIN');
            }

            onSuccess && onSuccess();

            if (response && response.navigateTo) {
                navigateTo(response.navigateTo);
            }
            if (gamePromisedToNavigate) {
                const game = gamePromisedToNavigate;
                setGamePromisedToNavigate(undefined);
                navigateToCasinoGamePage(navigateTo, game);
            }
            if (routePromisedToNavigate) {
                navigateTo(routePromisedToNavigate);
            }
        } catch (error: any) {
            const passwordExpiredErrorCode = '2008';
            const kycPendingErrorCode = '3011';
            const inactiveUserErrorCode = '3012';

            if (error.message === kycPendingErrorCode || error.message === inactiveUserErrorCode) {
                cams.set((s) => ({ ...s, email }));
                navigateTo(getRoute('registration-btob.cams'));
            }

            if (error.message === passwordExpiredErrorCode) {
                setIsEmailLoginFormVisible(false);
                setIsRenewPasswordFormVisible(true);
                setErrorMessage(translate('Your password has expired. Please set a new password.', 'ui.common'));
            } else {
                setErrorMessage(error.message as string);
            }
        } finally {
            setIsLoading(false);
        }
    }

    async function onRenewPasswordSuccess({ email, newPassword }) {
        useFormMethods.setValue('email', email);
        useFormMethods.setValue('password', newPassword);
        setIsEmailLoginFormVisible(true);
        setIsRenewPasswordFormVisible(false);
        await startLogin();
    }

    if (isForgotPasswordVisible) {
        return (
            <AuthLoginForgotPassword
                onClose={() => setIsForgotPasswordVisible(false)}
                useFormMethods={useFormMethods}
            />
        );
    }

    if (isBankIdLoginVisible) {
        return <AuthBankId onSuccess={onSuccess} />;
    }

    function isEmailLoginVisible() {
        if (areOtherLoginOptionsAvailable) {
            return preferredLoginMethod === LoginMethod.EMAIL;
        }
        return !isBoUserLogin;
    }

    function isGoogleLoginVisible() {
        if (areOtherLoginOptionsAvailable) {
            return preferredLoginMethod === LoginMethod.GOOGLE;
        }
        return isBoUserLogin;
    }

    function getPreferredLoginMethod() {
        const preferredLoginFromCache = storageGet<LoginMethod>(LocalStorage.PREFER_LOGIN);
        if (preferredLoginFromCache) {
            return preferredLoginFromCache;
        }
        if (isVisitorFromSweden) {
            return LoginMethod.BANK_ID;
        }
        return LoginMethod.EMAIL;
    }

    function startReopening() {
        setIsAppealFormOpen(true);
        setIsLoginFormOpen(false);
    }

    return (
        <Wrapper>
            {errorMessage && (
                <UiAlert failure>
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }} />
                </UiAlert>
            )}
            {isEmailLoginFormVisible && preferredLoginMethod === LoginMethod.EMAIL && (
                <Ui2Form onSubmit={startLogin} useFormMethods={useFormMethods} className="email-login">
                    <UiFormGroup className="form-group">
                        <Ui2FormTextInput
                            dataTest="input-email"
                            disabled={isLoading}
                            error={formState.errors.email}
                            name="email"
                            placeholder={translate('Email', 'ui.account')}
                            type="email"
                            validator={getFieldValidators(['email'])}
                        />
                    </UiFormGroup>
                    <UiFormGroup className="form-group">
                        <Ui2FormTextInput
                            dataTest="input-password"
                            disabled={isLoading}
                            error={formState.errors.password}
                            name="password"
                            placeholder={translate('Password', 'ui.account')}
                            type="password"
                        />
                        <div onClick={() => setIsForgotPasswordVisible(true)} className="forgot-password">
                            {translate('Forgot?', 'ui.common')}
                        </div>
                    </UiFormGroup>
                    {Boolean(appealId) && (
                        <UiButton
                            className="open-account-button"
                            color="primary"
                            type="button"
                            block
                            onClick={startReopening}
                        >
                            {translate('Open my account', 'ui.account')}
                        </UiButton>
                    )}
                    <UiButton
                        type="button"
                        color={appealId ? 'default' : 'primary'}
                        block
                        isLoading={isLoading || loading}
                        className="login-button"
                        data-test="button-submit"
                        isFormSubmitButton
                    >
                        {translate('Login', 'ui.common')}
                    </UiButton>
                </Ui2Form>
            )}
            {isFeatureAvailable(FEATURE.SMART_ID) && preferredLoginMethod === LoginMethod.SMART_ID && (
                <AuthSmartId onAuthenticationSuccess={onSuccess} />
            )}
            {isRenewPasswordFormVisible && (
                <AuthLoginRenewPassword
                    onSuccess={onRenewPasswordSuccess}
                    email={useFormMethods.getValues().email}
                    currentPassword={useFormMethods.getValues().password}
                />
            )}
            {preferredLoginMethod === LoginMethod.FACEBOOK && (
                <AuthLoginButtonFacebook
                    className="facebook-login-button"
                    onErrorHandler={setErrorMessage}
                    onAuthenticationStart={() => setErrorMessage('')}
                    onAuthenticationSuccess={onSuccess}
                    buttonComponent={UiButton}
                />
            )}
            {isGoogleLoginVisible() && (
                <AuthLoginButtonGoogle
                    className="google-login-button"
                    onErrorHandler={(message) => setErrorMessage(message)}
                    onAuthenticationStart={() => setErrorMessage('')}
                    onAuthenticationSuccess={onSuccess}
                    buttonComponent={UiButton}
                />
            )}
            {preferredLoginMethod === LoginMethod.BANK_ID && (
                <AuthLoginButtonBankId
                    onClick={() => {
                        setIsBankIdLoginVisible(true);
                    }}
                />
            )}
            {preferredLoginMethod === LoginMethod.APPLE && (
                <AuthLoginButtonApple
                    className="apple-login-button"
                    onErrorHandler={setErrorMessage}
                    onAuthenticationStart={() => setErrorMessage('')}
                    onAuthenticationSuccess={onSuccess}
                    buttonComponent={UiButton}
                />
            )}
            {preferredLoginMethod === LoginMethod.FINNISH_BANK_ID && <AuthLoginButtonFinnishBankId isPreferred />}
            {preferredLoginMethod === LoginMethod.FINNISH_MOBILE_ID && <AuthLoginButtonFinnishMobileId isPreferred />}
            {areOtherLoginOptionsAvailable && (
                <>
                    <UiLineSeparator text={translate('OR', 'ui.betslip')} />
                    <div className="other-options">
                        {isVisitorFromSweden && preferredLoginMethod !== LoginMethod.BANK_ID && (
                            <div>
                                <AuthButton
                                    dataTest="bank-id-login"
                                    onClick={() => {
                                        setIsBankIdLoginVisible(true);
                                    }}
                                >
                                    <Svg icon="bank-id-login" size={1.5} />
                                    <span>Bank ID</span>
                                </AuthButton>
                            </div>
                        )}
                        {isFeatureAvailable(FEATURE.FINNISH_TRUST_NETWORK) &&
                            isVisitorFromFinland &&
                            preferredLoginMethod !== LoginMethod.FINNISH_BANK_ID && (
                                <div>
                                    <AuthLoginButtonFinnishBankId />
                                </div>
                            )}
                        {isFeatureAvailable(FEATURE.FINNISH_TRUST_NETWORK) &&
                            isVisitorFromFinland &&
                            preferredLoginMethod !== LoginMethod.FINNISH_MOBILE_ID && (
                                <div>
                                    <AuthLoginButtonFinnishMobileId />
                                </div>
                            )}
                        {isFeatureAvailable(FEATURE.SMART_ID) &&
                            isVisitorFromEstonia &&
                            preferredLoginMethod !== LoginMethod.SMART_ID && (
                                <div>
                                    <AuthLoginButtonSmartId
                                        isPokerLogin={isPokerLogin}
                                        onClick={() => {
                                            setPreferredLoginMethod(LoginMethod.SMART_ID);
                                        }}
                                    />
                                </div>
                            )}
                        {preferredLoginMethod !== LoginMethod.EMAIL && (
                            <div>
                                <AuthButton
                                    dataTest="email-button"
                                    onClick={() => {
                                        setPreferredLoginMethod(LoginMethod.EMAIL);
                                    }}
                                >
                                    <Svg icon="email-login" size={1.5} />
                                    <span>{translate('Email', 'ui.common')}</span>
                                </AuthButton>
                            </div>
                        )}
                        {preferredLoginMethod !== LoginMethod.FACEBOOK && !isPokerLogin && (
                            <AuthLoginButtonFacebook
                                onErrorHandler={setErrorMessage}
                                onAuthenticationStart={() => setErrorMessage('')}
                                onAuthenticationSuccess={onSuccess}
                                buttonComponent={AuthButton}
                            >
                                <Svg icon="facebook-login" size={1.5} />
                                <span>Facebook</span>
                            </AuthLoginButtonFacebook>
                        )}

                        {preferredLoginMethod !== LoginMethod.GOOGLE && !isPokerLogin && (
                            <AuthLoginButtonGoogle
                                onErrorHandler={setErrorMessage}
                                onAuthenticationStart={() => setErrorMessage('')}
                                onAuthenticationSuccess={onSuccess}
                                buttonComponent={AuthButton}
                            >
                                <Svg icon="google-login" size={1.5} />
                                <span>Google</span>
                            </AuthLoginButtonGoogle>
                        )}

                        {preferredLoginMethod !== LoginMethod.APPLE && !isPokerLogin && (
                            <AuthLoginButtonApple
                                onErrorHandler={setErrorMessage}
                                onAuthenticationStart={() => setErrorMessage('')}
                                onAuthenticationSuccess={onSuccess}
                                buttonComponent={AuthButton}
                            >
                                <Svg icon="apple" size={1.5} />
                                <span>Apple</span>
                            </AuthLoginButtonApple>
                        )}
                    </div>
                </>
            )}
            {(isIOSDevice || !(isPokerLogin && isVisitorFromSweden)) && !isRenewPasswordFormVisible && (
                <AuthLoginFormRegistrationLink onClick={onClose} />
            )}
        </Wrapper>
    );
}
