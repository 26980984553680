import React from 'react';
import UiFlag from '../../ui/flag/UiFlag';
import Svg from '../../svg/Svg';
import UiPlaceholderIcon from '../../ui/placeholder/icon/UiPlaceholderIcon';

interface Props {
    icon: string | null | undefined;
    isRegion?: boolean;
    fallbackIcon?: string | null;
    size?: number;
    className?: string;
    shouldForceRenderFlag?: boolean;
    color?: string;
}

export default function SportCategoryIcon({
    icon,
    isRegion,
    fallbackIcon,
    size,
    shouldForceRenderFlag = false,
    color,
}: Props) {
    const iconName = icon || fallbackIcon;

    if (!iconName) {
        if (icon === undefined) {
            return <UiPlaceholderIcon />;
        }
        return null;
    }

    if (isRegion && icon) {
        return <UiFlag flag={iconName} shouldForceRenderFlag={shouldForceRenderFlag} />;
    }

    return <Svg icon={`${iconName}-ico`} size={size ? size : 1.125} color={color} />;
}
