import { getServiceUrl, httpGet } from '../services/api';
import { logger } from '../services/logger';

const getUrl = (url) => getServiceUrl('thunderbite', url);

export async function getFortuneWheelTime() {
    try {
        const url = getUrl('next-spin-time');
        const { valid_from } = await httpGet<{ valid_from: number }>(url);
        return new Date(valid_from);
    } catch (error) {
        logger.error('FortuneWheelMicroservice', 'demoLoadDataWithTryCatch', error);
    }
}
