import React, { ReactNode, useEffect, useState } from 'react';
import { stores } from '../../stores';
import { getRoute, isActiveRoute } from '../../services/router';
import { initializeResponsibleGamingTimeoutStatuses, isDepositPageBlocked } from '../../services/responsible-gaming';
import ResponsibleGamingSessionReminderAlert from './session-reminder/alert/ResponsibleGamingSessionReminderAlert';
import ResponsibleGamingProductBlockingAlert from './product-blocking/alert/ResponsibleGamingProductBlockingAlert';
import ResponsibleGamingLoginDurationLimitExceededAlert from './login-duration-limit-exceeded-alert/ResponsibleGamingLoginDurationLimitExceededAlert';
import ResponsibleGamingAnnualReport from './annual-report/ResponsibleGamingAnnualReport';
import UiProductClosed from '../ui/product-closed/UiProductClosed';
import { isFeatureAvailable } from '../../services/feature';
import { getUserProductBlockReason } from '../../services/responsible-gaming';
import { PRODUCT } from '../../types/common';
import { useStore } from '../../hooks/useStore';
import { FEATURE } from '../../services/types';
import { useSportsbookCountryBlock } from '../../hooks/useSportsbookCountryBlock';
import { isBlurredBlockedProduct } from '../../services/users/country';
import UiProductClosedBlurMessage from '../ui/product-closed/blur-message/UiProductClosedBlurMessage';

interface Props {
    children: ReactNode;
}

export default function ResponsibleGaming({ children }: Props) {
    const [blockedProducts] = useStore(stores.blockedProducts);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [shouldBlockDepositPage, setShouldBlockDepositPage] = useState(false);
    const isVirtualSportsRouteActive = isActiveRoute(getRoute('virtual-sports'), false);
    const isSportsbookRouteActive = !isVirtualSportsRouteActive && isActiveRoute(getRoute('sport'), false);
    const isBlockedCountry = useSportsbookCountryBlock();
    const isHorseRacingRouteActive =
        isFeatureAvailable(FEATURE.HORSE_RACING) && isActiveRoute(getRoute('horse-racing'), false);
    const isCasinoRouteActive =
        isActiveRoute(getRoute('casino'), false) || isActiveRoute(getRoute('casino-game'), false);
    const isPokerRouteActive = isActiveRoute(getRoute('poker'), false);
    const isDepositRouteActive = isActiveRoute(getRoute('deposit'), false);

    const closedProductByCountry = getClosedProductByCountry();
    const blurredProduct = isBlurredBlockedProduct() && closedProductByCountry;

    useEffect(() => {
        if (isAuthenticated) {
            initializeResponsibleGamingTimeoutStatuses().then(() => setShouldBlockDepositPage(isDepositPageBlocked()));
        }
    }, [isAuthenticated]);

    if (closedProductByCountry && !isBlurredBlockedProduct()) {
        return <UiProductClosed product={closedProductByCountry} />;
    }

    const shouldDisplayProductClosedAlert =
        (isSportsbookRouteActive && Boolean(getUserProductBlockReason(PRODUCT.SPORTSBOOK))) ||
        (isHorseRacingRouteActive && Boolean(getUserProductBlockReason(PRODUCT.RACEBOOK))) ||
        (isCasinoRouteActive && Boolean(getUserProductBlockReason(PRODUCT.CASINO))) ||
        (isPokerRouteActive && Boolean(getUserProductBlockReason(PRODUCT.POKER))) ||
        (isVirtualSportsRouteActive && Boolean(getUserProductBlockReason(PRODUCT.VIRTUAL_SPORTS))) ||
        (isDepositRouteActive && shouldBlockDepositPage);

    const productClosedAlert = (
        <>
            {isSportsbookRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.SPORTSBOOK} />}
            {isHorseRacingRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.RACEBOOK} />}
            {isCasinoRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.CASINO} />}
            {isPokerRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.POKER} />}
            {isVirtualSportsRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.VIRTUAL_SPORTS} />}
            {isDepositRouteActive && <ResponsibleGamingProductBlockingAlert product={PRODUCT.DEPOSIT} />}
        </>
    );

    function getClosedProductByCountry() {
        if (isSportsbookRouteActive && (blockedProducts[PRODUCT.SPORTSBOOK] || isBlockedCountry)) {
            return PRODUCT.SPORTSBOOK;
        }

        if (isVirtualSportsRouteActive && blockedProducts[PRODUCT.VS_HIGHLIGHT_GAMES]) {
            return PRODUCT.VS_HIGHLIGHT_GAMES;
        }
    }

    return (
        <>
            {isAuthenticated && (
                <>
                    <ResponsibleGamingSessionReminderAlert />
                    <ResponsibleGamingAnnualReport />
                </>
            )}
            <ResponsibleGamingLoginDurationLimitExceededAlert />
            {shouldDisplayProductClosedAlert ? productClosedAlert : children}
            {blurredProduct && <UiProductClosedBlurMessage />}
        </>
    );
}
