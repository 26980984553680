import clone from 'lodash/clone';
import uniqBy from 'lodash/uniqBy';
import { getServiceUrl, httpGet, httpPost, httpPut, withNonFailingAbort } from '../services/api';
import { logger } from '../services/logger';
import {
    getFlatCategories,
    setGroupedCategoriesResponseToStore,
    setSingleCategoryMarketTypesToStore,
} from '../services/sports/sport-category';
import {
    BetbuilderInfo,
    BoostedEventsParams,
    CategoryMatch,
    FavouriteCategoryWithMatches,
    FeaturedMatchesResponse,
    FirstMatch,
    LocalSportsUserSettings,
    MarketTypeCategory,
    PreMatchTree,
    SearchResult,
    SidebetMarketsResponse,
    SportCategoryWithMatches,
    TicketDetails,
} from '../services/sports/types';
import { getUserProvince } from '../services/user';
import { getUserCountry } from '../services/users/country';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { populateMatchPromotionsByMatches } from '../services/cms/promotion';
import { isMobile } from '../services/browser';
import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import { isFeatureAvailable, isGeniusStreamAvailable } from '../services/feature';
import { decideSportsbookMaintenance } from '../services/sports/maintenance';
import { isBYOD } from '../services/environment';
import { BetSlipMinimalMarket, FoHotEvent } from '@staycool/sbgate-types';
import { LiveTreeCategoryFo } from '@staycool/sbgate-types/dist/categoryTree/types';
import { Language } from '../services/language/types';
import { FoComboCardWithOdds } from '@staycool/sports-types/fo-combo-card';
import { MarketStatus, OutcomeStatus } from '@staycool/sports-types';
import { media } from '../stores/media/media';
import { Country } from '@staycool/location';
import { getSportsLayout } from '../services/layout/utils';
import { SportsLayout } from '../services/layout/types';
import { FEATURE, SportsBetslipInputFormat, SportsOddsFormat, SportsUserSettings } from '../services/types';
import { storageGet } from '../services/storage';
import { PRODUCT } from '../types/common';

const getUrl = (url) => getServiceUrl('sbgate', url);

export enum SportsTimeFormat {
    DEFAULT = 'DEFAULT',
    AMERICAN = 'AMERICAN',
}

async function sbgateRequest<T>(request: (...args: any) => Promise<T>, url: string, params?, key?: string) {
    try {
        return await withNonFailingAbort(
            (signal) => request(url, { ...params, ...(isBYOD() && { isRetail: isBYOD() }) }, { signal }),
            `${key || url}`,
        );
    } catch (error: any) {
        if (error?.product) {
            decideSportsbookMaintenance(error);
        }
        throw error;
    }
}

export function fetchPrematchTree(languageOverride?: string) {
    const language = languageOverride || getStoreValue(stores.language);
    const country = getUserCountry();
    const url = getUrl(`category/fo-tree/${language}`);
    return sbgateRequest<PreMatchTree>(httpGet, url, { country });
}

export interface UseSportsUserSettings {
    isAmericanBetslipInputFormat: boolean;
    isAmericanOddsFormat: boolean;
    updateUserSettings: (settings: Partial<LocalSportsUserSettings>) => Promise<void>;
    toggleLayout: () => Promise<void>;
    toggleBetslipInputFormat: () => Promise<void>;
    betslipInputFormat: SportsBetslipInputFormat;
    layout: false | SportsLayout;
    oddsFormat: SportsOddsFormat;
}

export async function loadPrematchTree() {
    try {
        const tree = await fetchPrematchTree();
        stores.sports.prematchTree.set(tree);
        const flatCategories = getFlatCategories(tree);
        stores.sports.flatCategories.set(flatCategories);
        return tree;
    } catch (error) {
        logger.error('SbgateMicroservice', 'loadPrematchTree', error);
    }
}

export async function loadLivebetTree(timeStamp?: Date) {
    try {
        const language = getStoreValue(stores.language);
        const country = getUserCountry();
        const url = getUrl(`category/fo-live`);
        const tree = await sbgateRequest<LiveTreeCategoryFo[]>(httpGet, url, { country, language, timeStamp });
        stores.sports.liveTree.set(tree);
    } catch (error) {
        logger.error('SbgateMicroservice', 'loadLivebetTree', error);
    }
}

export interface SportCategoryNavigation {
    id: string | number;
    url?: string;
    icon: string | null;
    isFlag?: boolean;
    label?: string;
    selected?: boolean;
    onClick?: () => void;
}

export async function loadHotEvents() {
    try {
        const language = getStoreValue(stores.language);
        const country = getUserCountry();
        const url = getUrl(`sports/fo-category/hot`);
        const params = {
            language,
            country,
        };
        const hotEvents = await sbgateRequest<FoHotEvent[]>(httpGet, url, params);
        stores.sports.hotEvents.set(hotEvents);
    } catch (error) {
        logger.error('SbgateMicroservice', 'loadHotEvents', error);
        stores.sports.hotEvents.set([]);
    }
}

export type BetHistoryRequest = {
    pageSize?: number;
    pageNumber?: number;
    ticketStatus?: string;
    timestampTo?: number;
    timestampFrom?: number;
    isCashout?: boolean;
    isCampaign?: boolean;
    ticketIds?: string;
};

export async function getBetHistory({
    pageSize,
    pageNumber,
    ticketStatus,
    timestampTo,
    timestampFrom,
    isCashout,
    isCampaign,
    ticketIds,
}: BetHistoryRequest) {
    const language = getStoreValue(stores.language);
    const layout = getSportsLayout();
    const url = getUrl('bets/history');
    const { tickets, hasNextPage } = await sbgateRequest<any>(httpGet, url, {
        pageSize,
        pageNumber,
        ticketStatus,
        timestampTo,
        timestampFrom,
        isCashout,
        isCampaign,
        language,
        ticketIds,
        layout,
    });
    return { tickets, pageSize, pageNumber, hasNextPage, timestampTo, timestampFrom, isCashout, isCampaign };
}

export async function getIsFirstBet() {
    const url = getUrl('/bets/history?language=en&pageSize=1');
    let tickets: any[] = [];

    try {
        tickets = (await sbgateRequest<{ tickets: any[] }>(httpGet, url)).tickets;
    } catch (error) {
        logger.error('SbgateMicroservice', 'getIsFirstBet', error);
    }

    return tickets.length === 0;
}

export type LeaderBoardTicket = {
    created_at: string;
    details: TicketDetails;
    first_bet_odds: number;
    first_match: FirstMatch;
    num_bets_lost: number;
    num_bets_won: number;
    rating: number;
    status: string;
    ticket_type: string;
};

type MatchesStartingSoonParams = { offset: number; sportCategory?: number; hoursBeforeMatchStart: number };

export async function updateMatchesStartingSoon({
    offset,
    sportCategory,
    hoursBeforeMatchStart,
}: MatchesStartingSoonParams) {
    const url = getUrl('sports/fo-match/coming-soon');
    const language = getStoreValue(stores.language);
    const country = getUserCountry();
    const layout = getSportsLayout();

    const { category, allSoonSportCategoryIds } = await sbgateRequest<{
        category: SportCategoryWithMatches;
        allSoonSportCategoryIds: number[];
    }>(httpGet, url, {
        offset,
        language,
        country,
        layout,
        locale: language,
        sportCategoryId: sportCategory,
        hoursBefore: hoursBeforeMatchStart,
    });

    setSingleCategoryMarketTypesToStore(category);

    const newMatches = category.matches || [];
    const existingMatches = getStoreValue(stores.sports.matchesSoon) || [];
    stores.sports.matchesSoon.set(uniqBy([...existingMatches, ...newMatches], (match) => match.id));

    if (!sportCategory && allSoonSportCategoryIds) {
        stores.sports.filterCategoryIds.set(allSoonSportCategoryIds);
    }

    populateMatchPromotionsByMatches(category.matches);

    return category;
}

type FetchFoCategoryParams = { categoryId: number; offset?: 0; limit?: number };

export async function fetchCategoryMatches({ categoryId = 0, offset = undefined }: FetchFoCategoryParams) {
    const limit = 5;
    const language = getStoreValue(stores.language);
    const country = getUserCountry();
    const layout = getSportsLayout();
    const url = getUrl('sports/fo-category/');

    const sportCategoriesWithMatches = await sbgateRequest<SportCategoryWithMatches[]>(
        httpGet,
        url,
        {
            categoryId,
            isMobile: 0,
            offset,
            language,
            country,
            layout,
            limit: limit + 1,
        },
        categoryId.toString(),
    );
    const hasMore = sportCategoriesWithMatches.length === limit + 1;
    if (hasMore) {
        sportCategoriesWithMatches.pop();
    }
    return { sportCategoriesWithMatches, hasMore };
}

export async function getCategoryMatches({ categoryId = 0, offset = undefined, limit = 5 }: FetchFoCategoryParams) {
    const layout = getSportsLayout();
    const params = { categoryId, offset, layout, limit };

    const { sportCategoriesWithMatches, hasMore } = await fetchCategoryMatches(params);
    setGroupedCategoriesResponseToStore(sportCategoriesWithMatches);

    const categoriesMatchesList = sportCategoriesWithMatches.flatMap((category) => category.matches);

    populateMatchPromotionsByMatches(categoriesMatchesList);

    return { sportCategoriesWithMatches, hasMore };
}

export async function fetchMatchesLiveBet({ isMobile, language, page }) {
    const isStream = getStoreValue(stores.sports.liveNow.isShowingMatchesWithStreamsOnly);
    const isGeniusAvailableForUser = isGeniusStreamAvailable();
    const [limit, offset] = findLimitOffset(page || 0);
    const url = getUrl('sports/live-now/');
    const country = getUserCountry();
    const layout = getSportsLayout();
    const params = { language, country, limit, offset, isMobile, layout, isStream, isGeniusAvailableForUser };

    return sbgateRequest<{
        categories?: SportCategoryWithMatches[];
        sportCategoryIds: number[];
        hasMore: boolean;
    }>(httpGet, url, params);
}

export async function fetchMatchesLiveBetCategory(categoryId, timestamp) {
    const url = getUrl(`sports/live-now/${categoryId}`);
    const country = getUserCountry();
    const language = getStoreValue(stores.language);
    const layout = getSportsLayout();
    const { isPhone } = getStoreValue(media);
    const params = { language, country, timestamp, isMobile: isPhone, layout };

    return sbgateRequest<{ categories: SportCategoryWithMatches[]; hasMore: boolean }>(httpGet, url, params);
}

export async function fetchHasStream() {
    const isGeniusAvailableForUser = isGeniusStreamAvailable();
    const url = getUrl(`sports/live-now/has-stream/`);
    const country = getUserCountry();
    const params = { country, isGeniusAvailableForUser };
    return sbgateRequest<boolean>(httpGet, url, params);
}

export async function getFilteredMatchesLiveBet(sportCategoryId: number, page?: number) {
    const isStream = getStoreValue(stores.sports.liveNow.isShowingMatchesWithStreamsOnly);
    const isGeniusAvailableForUser = isGeniusStreamAvailable();
    const [limit, offset] = findLimitOffset(page || 0);
    const language = getStoreValue(stores.language);
    const layout = getSportsLayout();
    const country = getUserCountry();
    const province = getUserProvince();
    const url = getUrl(`sports/live-now/${sportCategoryId}`);
    const params = {
        is_mobile: 0,
        locale: language,
        language,
        country,
        province,
        layout,
        limit,
        offset,
        isStream,
        isGeniusAvailableForUser,
    };

    const { categories, hasMore } = await sbgateRequest<{ categories: SportCategoryWithMatches[]; hasMore: boolean }>(
        httpGet,
        url,
        params,
    );

    if (categories?.length) {
        setGroupedCategoriesResponseToStore(categories);
    }
    return { categories, hasMore };
}

export async function getMarketInfoByOutcomeId(outcomeId) {
    const language = getStoreValue(stores.language);
    const country = getUserCountry();

    const layout = getSportsLayout();
    const url = getUrl(`sports/fo-market/betslip-info/${outcomeId}`);
    const market = await sbgateRequest<BetSlipMinimalMarket>(httpGet, url, {
        language,
        country,
        layout,
    });
    stores.sports.marketInfoById.set((marketInfoById) => {
        marketInfoById[market.id] = market;
    });
    return market;
}

function findLimitOffset(page: number) {
    const limits = isMobile() ? [5, 3] : [10, 6];
    const finalLimit = limits[limits.length - 1] || 6;
    const limit = page < limits.length ? limits[page] : finalLimit;
    const delta = Math.max(0, (page - limits.length) * finalLimit);
    const offset = sum(limits.slice(0, page)) + delta;
    return [limit, offset];
}

export async function fetchBoostedEvents(page = 0, sportCategoryId?: number) {
    const [limit, offset] = findLimitOffset(page);
    const language = getStoreValue(stores.language);
    const country = getUserCountry() as Country;
    const layout = getSportsLayout();
    const params: BoostedEventsParams = {
        language,
        country,
        layout,
        limit,
        offset,
    };

    let category: FeaturedMatchesResponse;
    if (sportCategoryId) {
        category = await sbgateRequest<FeaturedMatchesResponse>(
            httpGet,
            getUrl(`sports/match-featured/${sportCategoryId}`),
            params,
        );
    } else {
        category = await sbgateRequest<FeaturedMatchesResponse>(httpGet, getUrl('sports/match-featured/'), params);
    }
    setGroupedCategoriesResponseToStore(category.top_market_types_by_league);
    return category;
}

export async function fetchMatchWithTopMarketsById(matchId: number) {
    const language = getStoreValue(stores.language);
    const country = getUserCountry();
    const layout = getSportsLayout();
    const params = { language, country, layout, locale: language, matchIds: [matchId] };
    const url = getUrl('sports/fo-match');
    const category = await sbgateRequest<SportCategoryWithMatches>(httpPost, url, params);
    setSingleCategoryMarketTypesToStore(category);
    return category.matches[0];
}

export async function fetchSidebetMarkets(params: {
    matchId: number;
    marketTypeGroupId?: number;
    limit?: number;
    timestamp?: Date;
    matchStatus?: string;
}) {
    const language = getStoreValue(stores.language);
    const country = getUserCountry();
    const layout = getSportsLayout();
    const url = getUrl(`sports/fo-market/sidebets`);
    return sbgateRequest<SidebetMarketsResponse>(httpGet, url, { ...params, language, country, layout });
}

export async function getTicketDetailsByTickedId(ticketId) {
    const language = getStoreValue(stores.language);
    const layout = getSportsLayout();
    const url = getUrl(`bets/tickets/${ticketId}`);
    return sbgateRequest<TicketDetails>(httpGet, url, { ticketId, language, layout });
}

export async function getPublicTicketDetailsByTickedId(ticketId) {
    const language = getStoreValue(stores.language);
    const layout = getSportsLayout();
    const url = getUrl(`bets/tickets/public/${ticketId}`);
    return sbgateRequest<any>(httpGet, url, { ticketId, language, layout });
}

interface FoMatchListFilteredFilters {
    product?: string;
    offset?: number;
    limit?: number;
    matchIds?: number[];
    categoryIds?: number[];
    featured?: boolean;
    marketTypeIds?: number[];
    matchType?: 'NORMAL' | 'OUTRIGHT';
    sportCategoryIds?: number[];
    matchResultBefore?: Date;
}

export async function getMatchesByFilter(filters: FoMatchListFilteredFilters) {
    const language = getStoreValue(stores.language);
    const country = getUserCountry();
    const layout = getSportsLayout();
    const params = {
        ...filters,
        offset: filters.offset || 0,
        locale: language,
        language,
        country,
        layout,
    };
    const url = getUrl('sports/fo-match');
    const categoryResponse = await sbgateRequest<SportCategoryWithMatches>(httpPost, url, params);
    const { matches } = categoryResponse;
    setSingleCategoryMarketTypesToStore(categoryResponse);
    return matches;
}

export async function getMatchFavourites(matchIds: number[], page = 0) {
    const [limit, offset] = findLimitOffset(page);
    const language = getStoreValue(stores.language);
    const country = getUserCountry();
    const layout = getSportsLayout();
    const query = {
        matchIds: uniq(matchIds).join(','),
        language,
        country,
        layout,
        limit,
        offset,
    };
    const url = getUrl('sports/favourites');
    const categoryResponse = await sbgateRequest<FavouriteCategoryWithMatches>(httpGet, url, query);
    setGroupedCategoriesResponseToStore(categoryResponse.top_market_types_by_league);
    return categoryResponse;
}

export async function fetchCategoryBySlug(categoryFullSlug) {
    const categorySlugs = clone(categoryFullSlug);
    categorySlugs.reverse();
    const language = getStoreValue(stores.language);
    try {
        const url = getUrl(`category/by-slug/${language}/${categorySlugs.join('/')}`);
        return await sbgateRequest<any>(httpGet, url);
    } catch (error) {
        logger.error('SbgateMicroservice', 'fetchCategoryBySlug', error);
        return null;
    }
}

export function getSearchResults(search) {
    const language = getStoreValue(stores.language);
    const country = getUserCountry();
    const layout = getSportsLayout();
    const url = getUrl(`sports/search/v2`);
    return sbgateRequest<SearchResult[]>(httpGet, url, { search, language, country, layout });
}

export async function getMatchDataMinimal(matchId: number) {
    const url = getUrl(`sports/fo-match/${matchId}`);
    const language = getStoreValue(stores.language);
    const layout = getSportsLayout();
    const country = getUserCountry();

    return sbgateRequest<CategoryMatch>(httpGet, url, { language, layout, country });
}

interface SportRecommendations {
    matches: CategoryMatch[];
    market_types_by_league_category: MarketTypesByCategoryId;
}

interface MarketTypesByCategoryId {
    [categoryId: number]: MarketTypeCategory;
}

export async function loadSportRecommendationsByCountry(limit?: number) {
    try {
        const url = getUrl('sports/recommendations/popularity');

        const { matches, market_types_by_league_category: marketTypesByLeagueCategory } =
            await sbgateRequest<SportRecommendations>(httpGet, url, {
                language: getStoreValue(stores.language),
                country: getUserCountry(),
                layout: getSportsLayout(),
                isLocal: false,
                limit,
            });

        stores.sports.topMarketTypesByCategory.set((state) => ({ ...state, ...marketTypesByLeagueCategory }));
        populateMatchPromotionsByMatches(matches);
        return matches;
    } catch (error) {
        logger.error('SbgateMicroservice', 'loadSportRecommendationsByCountry', error);
        return [];
    }
}

export async function loadSportRecommendationsByTurnover(limit?: number, page?: number) {
    try {
        const url = getUrl('sports/recommendations/turnover');

        const { matches, market_types_by_league_category: marketTypesByLeagueCategory } =
            await sbgateRequest<SportRecommendations>(httpGet, url, {
                language: getStoreValue(stores.language),
                country: getUserCountry(),
                layout: getSportsLayout(),
                isLive: false,
                limit,
                page,
            });

        stores.sports.topMarketTypesByCategory.set((state) => ({ ...state, ...marketTypesByLeagueCategory }));
        if (matches) {
            populateMatchPromotionsByMatches(matches);
        }
        return matches;
    } catch (error) {
        logger.error('SbgateMicroservice', 'loadSportRecommendationsByTurnover', error);
        return [];
    }
}

export interface MarketsWithOutcomesMinimal {
    id: number;
    view_type: string;
    line: number;
    raw_line: number;
    status: MarketStatus;
    provider: number;
    outcomes: { id: number; status: OutcomeStatus; result_key: string; name: string }[];
}

interface FOMarketMinimalResponse {
    markets: {
        id: number;
        line: number;
        raw_line: number;
        status: 'OPEN' | 'SUSPENDED';
        outcomeIds: number[];
        provider: number;
    }[];
    common: { view_type: string; outcomes: { result_key: string; name: string }[] };
}

export async function fetchTopMarketsByMarketTypeAndMatch(
    matchId: number,
    marketTypeId: number,
    timestamp: Date,
): Promise<MarketsWithOutcomesMinimal[]> {
    const url = getUrl(`sports/fo-market`);
    const country = getUserCountry();
    const language = getStoreValue(stores.language);
    const layout = getSportsLayout();
    const { markets, common } = await sbgateRequest<FOMarketMinimalResponse>(
        httpGet,
        url,
        {
            matchId,
            marketTypeId,
            timestamp,
            country,
            language,
            layout,
        },
        `${url}-${matchId}-${marketTypeId}`,
    );
    const { outcomes: outcomesInfo = [], ...marketInfo } = common || {};
    return markets.map((m) => ({
        ...m,
        ...marketInfo,
        outcomes: m.outcomeIds.map((oId, i) => ({ ...(outcomesInfo[i] || {}), id: oId, status: 'OPEN' })),
    }));
}

export async function getSportsUserSettings() {
    const url = getUrl('sports/user-settings');
    return sbgateRequest<SportsUserSettings>(httpGet, url);
}

export async function updateSportsUserSettings(settings: Omit<SportsUserSettings, 'user_id'>) {
    const url = getUrl('sports/user-settings');
    return sbgateRequest<SportsUserSettings>(httpPut, url, settings);
}

export async function getBetbuilderInfo(offset: number, sportCategoryId?: number) {
    const useOpenbet = Boolean(storageGet('development.custom-betbuilder'));
    const url = getUrl(`sports/fo-match/bet-builder${useOpenbet ? '/openbet' : ''}`);

    const { category, allBetBuilderSportCategoryIds } = await sbgateRequest<BetbuilderInfo>(httpGet, url, {
        language: getStoreValue(stores.language),
        country: getUserCountry(),
        locale: getStoreValue(stores.language),
        layout: getSportsLayout(),
        limit: offset ? 6 : 10,
        offset,
        sportCategoryId,
    });
    setSingleCategoryMarketTypesToStore(category);
    return { matches: category.matches, allBetBuilderSportCategoryIds };
}

interface SportsbookMaintenance {
    maintenanceProduct: string;
    maintenanceEndDate: string | null;
    maintenanceMode: string;
}

export async function checkMaintenances() {
    const url = getUrl('maintenance');
    const { maintenanceProduct, maintenanceEndDate, maintenanceMode } = await sbgateRequest<SportsbookMaintenance>(
        httpGet,
        url,
    );
    decideSportsbookMaintenance({
        product: maintenanceProduct,
        endDate: maintenanceEndDate,
        maintenanceMode: maintenanceMode,
    });
}

export async function fetchAllComboCards(language: Language) {
    const country = getUserCountry();
    const url = getUrl('sports/fo-combo-card/all');
    return httpGet<FoComboCardWithOdds[]>(url, { language, country });
}

export async function validateUserCountry(country: string) {
    if (!isFeatureAvailable(FEATURE.SPORTSBOOK)) {
        return true;
    }
    const url = getUrl(`sports/blocked-country/${country}`);
    return sbgateRequest<boolean>(httpGet, url);
}

export async function loadBlockedProductsByPlayerCountry() {
    if (!isFeatureAvailable(FEATURE.BLOCKED_PRODUCTS_CHECK)) {
        return;
    }
    const getCasinoUrl = (url) => getServiceUrl('casino/fo', url);
    const casinoUrl = getCasinoUrl('virtual-sports-providers');
    const blockedProducts = {};
    try {
        const ipCountry = getStoreValue(stores.ipCountry);
        const isSportsbookBlocked = await validateUserCountry(ipCountry);
        blockedProducts[PRODUCT.SPORTSBOOK] = isSportsbookBlocked;
    } catch (error) {
        const sportsbookMaintenanceState = { endDate: null, liveBet: true, preMatch: true, betslip: false };
        stores.maintenance.sportsbookMaintenanceState.set(sportsbookMaintenanceState);
        logger.error('SbgateMicroservice', 'loadBlockedProductsByPlayerCountry', error);
    }

    try {
        const country = getStoreValue(stores.ipCountry);
        const blockedVirtualSportsProviders: { name: string }[] = await httpGet(casinoUrl, { country });
        blockedVirtualSportsProviders.forEach((provider) => {
            const formattedName = 'VS_' + provider.name.toUpperCase().replace(' ', '_');
            blockedProducts[formattedName] = true;
        });
    } catch (error) {
        stores.maintenance.isVirtualSportsHighlightMaintenance.set(true);
        logger.error('SbgateMicroservice', 'loadBlockedProductsByPlayerCountry', error);
    }
    stores.blockedProducts.set(blockedProducts);
}
