import { getServiceUrl, httpPost } from '../services/api';
import { logger } from '../services/logger';
import { GetBetbuilderBetPriceResponse, GetBetBuilderIdByMarketId } from '../services/openbet/types';
import { getActiveCurrency } from '../services/currency';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';

const getUrl = (url) => getServiceUrl('openbet', url);

export async function getBetbuilderBetPrice(
    matchId: number,
    outcomeIds: number[],
    saveBetSlip = true,
    stake: number = 1,
) {
    try {
        const currency = getActiveCurrency();
        const language = getStoreValue(stores.language);

        const url = getUrl(`fo-betbuilder/betbuilder-bet-price`);
        return await httpPost<GetBetbuilderBetPriceResponse>(url, {
            matchId,
            stake,
            outcomeIds,
            saveBetSlip,
            currency,
            language,
        });
    } catch (error) {
        logger.error('OpenbetMicroservice', 'getBetbuilderBetPrice', error);
    }
}

export async function getBetBuilderIdsByMarketIds(marketIds: string[]) {
    if (!marketIds.length) {
        return [];
    }
    try {
        const url = getUrl('fo-betbuilder/betbuilder-ids-by-market-ids');
        return await httpPost<GetBetBuilderIdByMarketId[]>(url, {
            marketIds,
        });
    } catch (error) {
        logger.error('OpenbetMicroservice', 'getBetBuilderIdsByMarketIds', error);
        return [];
    }
}
