import styled from 'styled-components';

type StyledProps = {
    $accentColor?: string;
};

export default styled.div<StyledProps>`
    border-radius: var(--ui-radius) var(--ui-radius) 0 0;
    background: var(--sport-category-bg);
    .header,
    .betting-end,
    .card-name,
    .card-name a,
    .card-type,
    .team-name {
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }
    .card-name a {
        color: inherit;
    }
    .header {
        justify-content: space-between;
        padding: 0 0.5rem;
        font-size: 0.625rem;
        height: 1.625rem;
        border-bottom: var(--surface-level-2-border);
        &.motd {
            background: var(--color-primary);
            &,
            .betting-end,
            .card-type {
                color: #202027;
            }
        }
    }

    .outcomes {
        display: flex;
        border-radius: 50%;
        border: ${({ $accentColor }) => `1px solid ${$accentColor || '#72e7e9'}`};
        width: 1rem;
        height: 1rem;
        span {
            margin: auto;
        }
    }

    .betting-end {
        color: var(--font-color-subtle);
    }
    .card-type {
        font-weight: 600;
        color: #f1f1f1;
    }
    .card-name {
        align-items: center;
        justify-content: center;
        margin: 0 0.5rem;
        padding: 0.5rem 0;
        font-size: 0.875rem;
        font-weight: 600;
        border-bottom: var(--surface-level-2-border);
        gap: 0.5rem;
        min-height: 35px;
    }
`;
