import React, { useEffect, useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { getAccountReopenForm } from '../../../microservices/users';
import { translate } from '../../../services/translate';
import { stores } from '../../../stores';
import UiAlert from '../../ui/alert/UiAlert';
import UiButton from '../../ui/button/UiButton';
import UiDotsLoader from '../../ui/dots-loader/UiDotsLoader';
import UiSubHeading from '../../ui/sub-heading/UiSubHeading';
import AuthAppealForm from './form/AuthAppealForm';
import Wrapper from './styles';

export default function AuthAppeal() {
    const [error, setError] = useState('');
    const [appealId, setAppealId] = useStore(stores.appeal.id);
    const [, setIsLoginFormOpen] = useStore(stores.modals.isLoginFormOpen);
    const [, setIsAppealFormOpen] = useStore(stores.appeal.isFormOpen);
    const [questions, setQuestions] = useState<string[]>([]);

    useEffect(() => {
        if (appealId) {
            load();
        }
    }, [appealId]);

    async function load() {
        try {
            const response = await getAccountReopenForm(appealId);
            if (response.questions) {
                setQuestions(response.questions.map((question) => translate(question)));
            }
        } catch (error: any) {
            setError(translate('Something went wrong', 'ui.common'));
        }
    }

    function close() {
        setAppealId('');
        setIsLoginFormOpen(true);
        setIsAppealFormOpen(false);
    }

    if (error) {
        return (
            <div>
                <UiAlert failure>{error}</UiAlert>
                <UiButton type="button" block onClick={close}>
                    {translate('Back', 'ui.common')}
                </UiButton>
            </div>
        );
    }

    if (!questions.length) {
        return <UiDotsLoader />;
    }

    return (
        <Wrapper>
            <UiSubHeading className="title">{translate('Open my account', 'ui.account')}</UiSubHeading>
            <p className="text">
                {translate(
                    `Ready to jump back in? Let's make sure everything's good to go with a few questions:`,
                    'ui.account',
                )}
            </p>
            <AuthAppealForm close={close} questions={questions} setError={setError} />
        </Wrapper>
    );
}
