import merge from 'lodash/merge';
import { stringify } from 'query-string';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { getDecodedToken, isTokenExpired, isTokenRenewalRequired, removeToken, setToken } from './token';
import isString from 'lodash/isString';
import { isMobileApp } from './mobile-app';
import { storageGet } from './storage';
import { translate } from './translate';
import { LocalStorage } from './local-storage/types';

const RESPONSE_ERROR = {
    TOKEN_INVALID: 'Token is invalid',
    TOKEN_EXPIRED_LOGIN_REQUIRED: 'Token expired, re-login required',
    TOKEN_EXPIRED: 'Token has expired',
    TOKEN_DELETED: 'Token cannot be renewed',
};

export let apiRequestsOnGoing = 0;

const abortControllerByKey: Record<string, AbortController> = {};
const abortedRequestPromiseByKey: Record<string, any[]> = {};

/**
 * A special function that always returns the latest calls value
 * when multiple requests are happening to the same "key"
 * to be used inside our components without changing component logic,
 * the promise will return the updated value in all calls
 * it should be safe even if the re-renders are out of sync
 * @param fn any fn that receives abort signal
 * @param key on which key it will deduplicate the function calls
 */
export async function withNonFailingAbort<F extends (signal: AbortSignal) => any>(
    fn: F,
    key: string,
): Promise<ReturnType<F>> {
    if (abortControllerByKey[key]) {
        abortControllerByKey[key].abort('Duplicate request');
    }
    const abortController = new AbortController();
    abortControllerByKey[key] = abortController;
    return new Promise(async (resolve, reject) => {
        abortedRequestPromiseByKey[key] = [...(abortedRequestPromiseByKey[key] || []), { resolve, reject }];
        try {
            const result = await fn(abortController.signal);
            abortedRequestPromiseByKey[key]?.forEach(({ resolve }) => resolve(result));
            delete abortedRequestPromiseByKey[key];
            delete abortControllerByKey[key];
        } catch (e) {
            if (abortController.signal.aborted) {
                return;
            }
            abortedRequestPromiseByKey[key]?.forEach(({ reject }) => reject(e));
            delete abortedRequestPromiseByKey[key];
            delete abortControllerByKey[key];
        }
    });
}

export async function httpGet<T>(url, params?, config?: RequestInit) {
    await tryUpdateToken(url);

    url = params ? `${url}?${stringify(params)}` : url;
    return handleRequest(() =>
        fetch(
            url,
            extendConfig(
                {
                    method: 'GET',
                    headers: getHeaders(),
                },
                config,
            ),
        ),
    ) as Promise<T>;
}

export async function httpPost<T>(url, data = {}, config?: RequestInit) {
    await tryUpdateToken(url);

    return handleRequest(() =>
        fetch(
            url,
            extendConfig(
                {
                    method: 'POST',
                    headers: getHeaders(),
                    body: JSON.stringify(data),
                },
                config,
            ),
        ),
    ) as Promise<T>;
}

export async function httpPatch<T = any>(url, data = {}, config?) {
    return handleRequest(() =>
        fetch(
            url,
            extendConfig(
                {
                    method: 'PATCH',
                    headers: getHeaders(),
                    body: JSON.stringify(data),
                },
                config,
            ),
        ),
    ) as Promise<T>;
}

export async function httpDelete<T>(url, config?: RequestInit) {
    await tryUpdateToken(url);

    return handleRequest(() =>
        fetch(
            url,
            extendConfig(
                {
                    method: 'DELETE',
                    headers: getHeaders(),
                },
                config,
            ),
        ),
    ) as Promise<T>;
}

export async function httpPostFile(url, data: any = {}) {
    await tryUpdateToken(url);
    const token = getStoreValue(stores.token);
    const headers: any = token ? { cbauth: `Bearer ${token}` } : {};

    return handleRequest(() =>
        fetch(url, {
            method: 'POST',
            headers,
            body: data,
        }),
    );
}

export async function httpPut<T>(url, data) {
    await tryUpdateToken(url);

    return handleRequest(() =>
        fetch(url, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(data),
        }),
    ) as Promise<T>;
}

function extendConfig(config: RequestInit, extraConfig?: RequestInit) {
    if (extraConfig?.headers) {
        config.headers = merge(config.headers, extraConfig.headers);
    }

    return { ...config, signal: extraConfig?.signal };
}

async function handleRequest(requestFactory) {
    apiRequestsOnGoing++;
    try {
        return await parseResponse(await requestFactory());
    } catch (error: any) {
        if (
            [
                RESPONSE_ERROR.TOKEN_INVALID,
                RESPONSE_ERROR.TOKEN_EXPIRED_LOGIN_REQUIRED,
                RESPONSE_ERROR.TOKEN_DELETED,
            ].some((message) => error.message?.includes(message))
        ) {
            removeToken();
            window.location.reload();
        }

        if (isTokenExpiredResponse(error)) {
            await renewToken();
            return parseResponse(await requestFactory());
        }
        throw error;
    } finally {
        apiRequestsOnGoing--;
    }
}

async function parseResponse(response: Response) {
    updateApiVersion(response);

    if (response.status < 200 || response.status >= 300) {
        const parsedBody = await parseBodyFromResponse(response);
        const errorMessage = `Request to ${response.url} failed with ${response.status}`;
        if (typeof parsedBody !== 'object') {
            throw new Error(`${errorMessage}: ${parsedBody}`);
        }
        if (Array.isArray(parsedBody)) {
            throw new Error(`${errorMessage}: ${JSON.stringify(parsedBody)}`); // I don't think this will happen but better safe than sorry
        }
        const error = new Error(`${errorMessage}: ${JSON.stringify(parsedBody)}`);
        Object.assign(error, parsedBody);
        throw error;
    }
    return parseBodyFromResponse(response);
}

function updateApiVersion(response) {
    const apiVersion = response.headers.get('api-version');
    if (!apiVersion) {
        return;
    }

    const lastApiVersion = getStoreValue(stores.apiVersion.lastApiVersion);
    if (lastApiVersion !== null && lastApiVersion !== apiVersion) {
        stores.apiVersion.reloadRequired.set(true);
    }
    stores.apiVersion.lastApiVersion.set(apiVersion);
}

function isTokenExpiredResponse(response) {
    const isUnauthorizedTokenHasExpiredResponse =
        response.title === 'Unauthorized' && response.description === RESPONSE_ERROR.TOKEN_EXPIRED;
    const isTokenHasExpiredResponse = response.message?.includes(RESPONSE_ERROR.TOKEN_EXPIRED);
    return isUnauthorizedTokenHasExpiredResponse || isTokenHasExpiredResponse;
}

async function parseBodyFromResponse(response) {
    try {
        const parsedBody = await response.clone().json();
        if (isString(parsedBody) && (parsedBody.startsWith('[') || parsedBody.startsWith('{'))) {
            return JSON.parse(parsedBody);
        }

        return parsedBody;
    } catch (error) {
        const textResponse = await response.text();
        return isIncapsulaError(textResponse)
            ? { message: translate('incapsula_technical_error', 'ui.common') }
            : textResponse;
    }
}

function getHeaders() {
    const token = getStoreValue(stores.token) || getStoreValue(stores.verification.data).temporaryToken;
    const deviceType = getStoreValue(stores.deviceType);

    const headers: any = {
        'Content-Type': 'application/json; charset=utf-8',
    };

    if (token) {
        headers.cbauth = `Bearer ${token}`;
        const { sub, login_session_id } = getDecodedToken(token);
        headers.User_Id = sub;
        headers.Login_Session_Id = login_session_id;
    }
    if (deviceType) {
        headers['X-Device'] = deviceType;
    }
    const appHeaders = getAppHeaders();
    Object.assign(headers, appHeaders);

    return headers;
}

function getAppHeaders(): Record<string, string> {
    const applicationType = getStoreValue(stores.applicationType);
    if (applicationType) {
        return { 'X-App': applicationType };
    }
    if (isMobileApp()) {
        const appLicences = storageGet<string>(LocalStorage.AVAILABLE_LICENCES);
        return { 'X-App': 'mobile-app', ...(appLicences && { STORAGE_APP_LICENSE: appLicences }) };
    }
    return {};
}

export function getServiceUrl(serviceName, url) {
    const serviceUrl = `/s/${serviceName}/${url}`;

    if (process.env.REACT_APP_CI) {
        return getProxyUrl(serviceUrl);
    }

    if (window?.coolb2b?.apiBaseUrl) {
        return `${window.coolb2b.apiBaseUrl}${serviceUrl}`;
    }

    return serviceUrl;
}

export function getProxyUrl(url = '') {
    return `http://${window.location.hostname}:7000${url}`;
}

function isIncapsulaError(message: string) {
    return message.toLowerCase().includes('incapsula');
}

let renewTokenPromise;
const renewTokenUrl = getServiceUrl('auth', 'renew-token');

export async function renewToken(isRetry = false) {
    try {
        renewTokenPromise = httpPost(renewTokenUrl);
        const requestStartTime = Date.now();
        const { token } = await renewTokenPromise;
        const requestTime = (requestStartTime + Date.now()) / 2;
        setToken(token, requestTime);
    } catch (error: any) {
        if (![401, 403].includes(error.status) && !isRetry) {
            await renewToken(true);
        } else {
            stores.logoutViaStoreDate.set(new Date());
            if (isRetry) {
                throw error;
            }
        }
    } finally {
        renewTokenPromise = undefined;
    }
}

async function tryUpdateToken(url = '') {
    const token = getStoreValue(stores.token);
    if (url.includes(renewTokenUrl) || !token) {
        return;
    }

    if (renewTokenPromise) {
        return renewTokenPromise;
    }

    if (isTokenExpired()) {
        await renewToken();
        if (isTokenExpired()) {
            stores.logoutViaStoreDate.set(new Date());
            return;
        }
    }

    if (isTokenRenewalRequired()) {
        return renewToken();
    }
}
