import { getServiceUrl, httpGet, httpPost } from '../services/api';
import { storageGet } from '../services/storage';
import { stores } from '../stores';
import { getStoreValue } from '../stores/store/utils';
import { translate } from '../services/translate';
import { getCodeProvider, getCodeWithoutProvider, suffixMap } from '../services/refer-a-friend';
import { getDocumentHost } from '../services/util';
import { Providers } from './refer-a-friend/types';
import { User } from '../services/types';
import { ObjectValues } from '../services/ts-utils';

const getUrl = (url) => getServiceUrl('refer-a-friend', url);

export async function loadParticipant(userId: string) {
    const url = getUrl('api/participants/load');
    const response = await httpGet<any>(url, { userId });

    return {
        ...response,
        invitations: response.invitations || [],
    };
}

export function getParticipantProviders(code: string, user: User): Providers {
    const language = getStoreValue(stores.language);
    const referUrl = `https://${getDocumentHost()}/${language}/refer/?code=${code}`;
    const urlTo = (providerSuffix: ObjectValues<typeof suffixMap>) => `${referUrl}-${providerSuffix}`;
    const emailUrl = `${referUrl}-${suffixMap.email}`;

    return {
        facebook: {
            url: urlTo(suffixMap.facebook),
            text: translate('raf.short-invite'),
        },
        facebookMessenger: {
            url: `fb-messenger://share/?link=${urlTo(suffixMap.facebookMessenger)}`,
        },
        email: {
            url: urlTo(suffixMap.email),
            subject: translate('Subject', 'raf.email', [user.firstName]),
            body: translate('Body', 'raf.email', [user.firstName, emailUrl, emailUrl]).replace(/\\n/g, '\n'),
        },
        link: {
            text: urlTo(suffixMap.link),
        },
        sms: {
            url: `sms:?&body=${translate('raf.short-invite')}: ${urlTo(suffixMap.sms)}`,
        },
    };
}

export async function findByCode(code) {
    const url = getUrl('api/participants/find-by-code');
    return httpGet<{ firstName: string }>(url, { code: getCodeWithoutProvider(code) });
}

export async function trackAction(action) {
    const code = storageGet('raf_code');

    if (code) {
        const language = getStoreValue(stores.language);

        return httpPost(getUrl('api/participants/track'), {
            action,
            code: getCodeWithoutProvider(code),
            source: getCodeProvider(code),
            language,
        });
    }
}

export async function linkInvitation(userId, code) {
    return httpPost<any>(getUrl('api/participants/link'), {
        userId,
        code: getCodeWithoutProvider(code),
        source: getCodeProvider(code),
    });
}

export async function remindParticipant(invitationId) {
    const url = getUrl('api/participants/remind');
    const response = await httpPost<any>(url, { invitationId });
    const isReminded = response.status === 'reminded';

    return { isReminded };
}

export async function getRafSettingsByKey(settingsKey) {
    const url = getUrl('api/settings/find-by-key');
    return httpGet<{ id: number; key: string; value: string }>(url, { key: settingsKey });
}
