import { Country, Licence, Market } from '@staycool/location';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../../hooks/useStore';
import { getLicence } from '../../../../services/licence';
import { MGA_LICENCE_LINK } from '../../../../services/responsible-gaming';
import { getRoute } from '../../../../services/router';
import { getUserMarketCode } from '../../../../services/user';
import { isUserFromCountry } from '../../../../services/users/country';
import { stores } from '../../../../stores';
import { environment } from '../../../../stores/environment/environment';
import Wrapper from './styles';

export default function CoolbetFooterLegalLogos() {
    const [filteredResponsiblePartners, setFilteredResponsiblePartners] = useState<Partner[]>([]);
    const [isAuthenticated] = useStore(stores.isAuthenticated);
    const [market, setMarket] = useState(getUserMarketCode());
    const [{ IMAGE_BASE_URL }] = useStore(environment);
    const imagePath = `${IMAGE_BASE_URL}responsible-gaming/`;
    const licence = getLicence();

    const responsiblePartners: Partner[] = [
        {
            name: 'Blakors',
            externalLink: 'https://www.blakors.no/',
            allowedMarkets: [Market.NORWAY],
            width: 64,
        },
        {
            name: 'Betblocker',
            externalLink: 'https://betblocker.org/',
            width: 40,
            allowedMarkets: [
                Market.NORWAY,
                Market.ESTONIA,
                Market.FINLAND,
                Market.CHILE,
                Market.PERU,
                Market.MEXICO,
                Market.SWEDEN,
                Market.ICELAND,
                Market.ECUADOR,
                Market.CANADA,
            ],
        },
        {
            name: 'ESRB',
            externalLink: 'https://www.esrb.org/ratings-guide/',
            allowedMarkets: [Market.USA_NEVADA, Market.MEXICO, Market.USA_MISSISSIPPI],
            width: 27,
        },
        {
            name: 'GamblersAnonymous',
            externalLink: 'http://www.gamblersanonymous.org/ga/',
            allowedMarkets: [Market.PERU, Market.MEXICO, Market.ECUADOR, Market.CANADA],
            width: 65,
        },
        {
            name: 'GamCare',
            externalLink: 'https://www.gamcare.org.uk/',
            allowedMarkets: [Market.PERU, Market.MEXICO, Market.ECUADOR, Market.CANADA],
            width: 36,
        },
        {
            name: 'Hjelpelinjen',
            externalLink: 'https://hjelpelinjen.no/',
            allowedMarkets: [Market.NORWAY],
            width: 157,
        },
        {
            name: 'Noustamiskeskus',
            externalLink: 'https://15410.ee/',
            allowedMarkets: [Market.ESTONIA],
            width: 210,
        },
        {
            name: 'Segob',
            externalLink: 'https://www.gob.mx/segob/',
            allowedMarkets: [Market.MEXICO],
            width: 126,
        },
        {
            name: 'Spelberoende',
            externalLink: 'http://www.spelberoende.se/',
            allowedMarkets: [Market.SWEDEN],
            width: 228,
        },
        {
            name: 'Spillavhengighet',
            externalLink: 'http://spillavhengighet.no/',
            allowedMarkets: [Market.NORWAY],
            width: 171,
        },
        {
            name: 'Stodlinjen',
            externalLink: 'https://www.stodlinjen.se/',
            allowedMarkets: [Market.SWEDEN],
            width: 194,
        },
    ];
    const ageRestrictionByMarket: Record<Market, number> = {
        [Market.CANADA]: 19,
        [Market.CANADA_ONTARIO]: 19,
        [Market.CHILE]: 18,
        [Market.ECUADOR]: 18,
        [Market.ESTONIA]: 21,
        [Market.FINLAND]: 18,
        [Market.ICELAND]: 18,
        [Market.MEXICO]: 18,
        [Market.NORWAY]: 18,
        [Market.PERU]: 18,
        [Market.REST_OF_THE_WORLD]: 18,
        [Market.SWEDEN]: 18,
        [Market.USA]: 21,
        [Market.USA_ARIZONA]: 21,
        [Market.USA_MASSACHUSETTS]: 21,
        [Market.USA_MISSISSIPPI]: 21,
        [Market.USA_NEVADA]: 21,
        [Market.USA_NEW_YORK]: 21,
        [Market.USA_WEST_VIRGINIA]: 21,
    };

    useEffect(() => {
        setFilteredResponsiblePartners(filterResponsiblePartnersByCountry(responsiblePartners));
    }, []);

    useEffect(() => {
        setMarket(getUserMarketCode());
    }, [isAuthenticated]);

    function filterResponsiblePartnersByCountry(responsiblePartners: Partner[]) {
        const isPeruvianUser = isUserFromCountry(Country.PERU);
        const licence = isPeruvianUser ? Licence.PERU : getLicence();
        return responsiblePartners.filter((partner) => partner.allowedMarkets.includes(licence));
    }

    const licenceContent: Record<
        Licence,
        {
            logoImage?: string;
            logoAlternateText?: string;
            externalLink?: string;
            isColoredLogo?: boolean;
            logoStyles?: Record<string, string>;
            width?: number;
            height?: number;
        }
    > = {
        [Licence.ESTONIA]: {
            logoImage: 'Emta',
            logoAlternateText: 'EMTA',
            externalLink: 'https://www.emta.ee/',
            width: 178,
        },
        [Licence.MALTA]: {
            logoImage: 'malta-gaming-authority',
            logoAlternateText: 'Malta Gaming Authority',
            externalLink: MGA_LICENCE_LINK,
        },
        [Licence.MEXICO]: {
            logoImage: 'gobernacion-de-mexico',
            logoAlternateText: 'Gobierno de Mexico',
            externalLink: 'https://www.gob.mx/segob/',
        },
        [Licence.PERU]: {
            logoImage: '',
            logoAlternateText: '',
            externalLink: '',
        },
        [Licence.SIMULATED_GAMING]: {
            logoImage: '',
            logoAlternateText: '',
            externalLink: '',
        },
        [Licence.SWEDEN]: {
            logoImage: 'spelinspektionen',
            logoAlternateText: 'Spelinspektionen',
            externalLink: 'https://www.spelinspektionen.se/',
        },
        [Licence.USA_ARIZONA]: {
            logoImage: 'malta-gaming-authority',
            logoAlternateText: 'Malta Gaming Authority',
            externalLink: MGA_LICENCE_LINK,
        },
        [Licence.USA_MASSACHUSETTS]: {
            logoImage: 'malta-gaming-authority',
            logoAlternateText: 'Malta Gaming Authority',
            externalLink: MGA_LICENCE_LINK,
        },
        [Licence.USA_NEVADA]: {
            logoImage: 'NGCB-logo',
            logoAlternateText: 'Nevada Gaming Control Board',
            externalLink: 'https://gaming.nv.gov/',
            logoStyles: { minHeight: '60px' },
        },
        [Licence.USA_MISSISSIPPI]: {
            logoImage: '',
            logoAlternateText: '',
            externalLink: '',
        },
        [Licence.USA_NEW_YORK]: {
            logoImage: 'malta-gaming-authority',
            logoAlternateText: 'Malta Gaming Authority',
            externalLink: MGA_LICENCE_LINK,
        },
    };

    function renderLicenceContent() {
        if ([Licence.PERU, Licence.SIMULATED_GAMING].includes(licence)) {
            return null;
        }

        const {
            logoImage,
            logoAlternateText,
            externalLink,
            isColoredLogo = false,
            logoStyles = {},
            width,
            height,
        } = licenceContent[licence];

        if (!logoImage || !externalLink) {
            return null;
        }

        return (
            <a
                className="coolbet-footer-legal-logos-logo-link"
                target="_blank"
                href={externalLink}
                rel="noopener noreferrer"
            >
                <img
                    loading="lazy"
                    className={`coolbet-footer-legal-logos-logo license-logo ${isColoredLogo && 'colored'}`}
                    width={width}
                    height={height}
                    srcSet={`${imagePath}${logoImage?.toLowerCase()}.png?auto=format&dpr=1 1x,
              ${imagePath}${logoImage?.toLowerCase()}.png?auto=format&dpr=2 2x,
              ${imagePath}${logoImage?.toLowerCase()}.png?auto=format&dpr=3 3x`}
                    src={`${imagePath}${logoImage?.toLowerCase()}.png?auto=format`}
                    alt={logoAlternateText}
                    style={logoStyles}
                />
            </a>
        );
    }

    return (
        <Wrapper>
            <div className="coolbet-footer-legal-logos-container">
                {filteredResponsiblePartners.map((partner) => (
                    <a
                        className="coolbet-footer-legal-logos-logo-link"
                        target="_blank"
                        key={partner.name}
                        href={partner.externalLink}
                        rel="noopener noreferrer"
                    >
                        <img
                            loading="lazy"
                            className="coolbet-footer-legal-logos-logo"
                            width={partner.width}
                            height={partner.height}
                            srcSet={`${imagePath}${partner.name.toLowerCase()}.png?auto=format&dpr=1 1x,
              ${imagePath}${partner.name.toLowerCase()}.png?auto=format&dpr=2 2x,
              ${imagePath}${partner.name.toLowerCase()}.png?auto=format&dpr=3 3x`}
                            src={`${imagePath}${partner.name.toLowerCase()}.png?auto=format`}
                            alt={partner.name}
                        />
                    </a>
                ))}
                {renderLicenceContent()}
                <NavLink
                    className="coolbet-footer-legal-logos-logo-link"
                    key={ageRestrictionByMarket[market]}
                    to={getRoute('responsible-gaming')}
                >
                    <img
                        loading="lazy"
                        className="coolbet-footer-legal-logos-logo"
                        width="40"
                        srcSet={`${imagePath}${ageRestrictionByMarket[market]}.png?auto=format&dpr=1 1x,
              ${imagePath}${ageRestrictionByMarket[market]}.png?auto=format&dpr=2 2x,
              ${imagePath}${ageRestrictionByMarket[market]}.png?auto=format&dpr=3 3x`}
                        src={`${imagePath}${ageRestrictionByMarket[market]}.png?auto=format`}
                        alt={ageRestrictionByMarket[market].toString()}
                    />
                </NavLink>
                {licence === Licence.PERU && (
                    <a
                        className="coolbet-footer-legal-logos-logo-link"
                        target="_blank"
                        key="bookOfComplaints"
                        href={getRoute('support.complaint')}
                        rel="noopener noreferrer"
                    >
                        <img
                            loading="lazy"
                            className="coolbet-footer-legal-logos-logo"
                            srcSet={`${imagePath}book-of-complaints.png?auto=format&dpr=1 1x,
              ${imagePath}book-of-complaints.png?auto=format&dpr=2 2x,
              ${imagePath}book-of-complaints.png?auto=format&dpr=3 3x`}
                            src={`${imagePath}book-of-complaints.png?auto=format`}
                            alt="Book of complaints"
                        />
                    </a>
                )}
            </div>
        </Wrapper>
    );
}

type Partner = {
    allowedMarkets: string[];
    externalLink: string;
    name: string;
    width?: number;
    height?: number;
};
